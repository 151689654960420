import React, { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";
import "./Navbar.css";
import { useAuth } from "../../services/auth/use-auth";
import { Typography } from "@mui/material";

const Navbar = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  function signOut() {
    auth.signout();
    navigate("/");
  }

  return (
    <div className="">
      <div className="bg-navbar">
        <div className="container">
          <header className="d-flex flex-wrap justify-content-center py-3 my-auto text-center h-100">
            <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto">
              <Link to="/" className="text-decoration-none">
                <span className="navbar-logo2">Smart AI&nbsp;</span>
                <span className="navbar-company2">Stock Images</span>
                <br />
                <Typography variant="caption" 
                  sx={{
                    color: "white",
                    fontSize: "0.75rem",
                    textTransform: "uppercase",
                  }}>
                a smartai.studio production
                </Typography>
              </Link>               
            </div>
      
            <ul className="nav nav-pills d-flex align-items-center justify-content-center">
              <li className="nav-item">
                <Dropdown
                  list={[
                    { label: "Images", link: "/" },
                    { label: "Content Packs", link: "/packs" },
                  ]}
                >
                  Explore
                </Dropdown>
              </li>
              <li className="nav-item">
                <Link to="/pricing" className="nav-link">
                  <span className="d-flex text-white-thin">Pricing</span>
                </Link>
              </li>
              {auth.user ? (
                <li className="nav-item">
                  <Dropdown
                    list={[
                      { label: "My Images", link: "/profile" },
                      {
                        label: "Manage Subscriptions",
                        link: "/profile/edit",
                        search: "?tab=membership",
                      },
                      {
                        label: "Manage Profile",
                        link: "/profile/edit",
                        search: "?tab=settings",
                      },
                      { label: "Sign Out", link: "/signout" }
                    ]}
                  >
                    <i className="fa-regular fa-user mx-1"></i>
                    {auth.user.displayName}
                  </Dropdown>
                </li>
              ) : (
                <>
                  <li className="nav-item">
                    <Link to="/login" className="nav-link">
                      <i className="fa-regular fa-user text-white">
                        <span className="text-white-thin">
                          &nbsp;Log&nbsp;in
                        </span>
                      </i>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/signup" className="nav-link">
                      <div className="button">Join</div>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </header>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
