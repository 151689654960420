import React, { useState, useEffect } from "react";
import GalleryTile from "./GalleryTile";
import { Masonry, useInfiniteLoader } from "masonic";
import {
  Container,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function GalleryTiles({ data, loadMore }) {
  const onMobile = useMediaQuery("(max-width:600px)");
  const onXSMMobile = useMediaQuery("(max-width:331px)");
  const navigate = useNavigate();

  const download = async (url) => {
    // console.log("downloadImage: " + JSON.stringify(data));
    var urlDownload = url ? url : data.public_url;
    const blob = await fetch(urlDownload).then((r) => r.blob());
    const urlBlob = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = urlBlob;
    link.target = "_blank";
    link.download =
      "SmartAI-StockImages_" +
      urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
    link.click();
  };

  // Create tile for each item in data array
  // Pass data to each tile and assign a key
  return (
    <Container maxWidth="lg">
      <ImageList
        variant="masonry"
        cols={onMobile ? (onXSMMobile ? 1 : 2) : 5}
        gap={20}
        sx={{ padding: 5 }}
      >
        {data.map((item, index) => (
          <ImageListItem key={index}>
            <img
              src={item.public_url_thumbnail}
              srcSet={`${item.public_url_thumbnail} 1x, ${item.public_url_thumbnail} 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderRadius: 4,
                boxShadow: "2px 2px 6px 3px rgba(0, 0, 0, 0.3)",
              }}
            />
            <ImageListItemBar
              className="show-on-hover-no-click"
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                  "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                height: "100%",
              }}
              position="top"
              title={item.title.length > 30 ? item.title.substring(0, 30) + "..." : item.title}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.title}`}
                  onClick={() => download(item.public_url)}
                >
                  <FontAwesomeIcon size="lg" icon={solid("download")} />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
}
