import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import logo from '../../assets/img/logo.png';
import platform from '../../assets/img/logo2_tiny.png';
import { Card } from "@mui/joy";

function Footer() {

  const StockImageLogo = (
    <Link to="/" className="text-decoration-none">
      <img src={logo} className="logo-si img-fluid" />
      <span className="navbar-logo">
        Smart AI &nbsp;
        <br />
      </span>
      <span className="navbar-company">Stock Images</span>
    </Link>
  );

  const SmartAIPlatformLogo = (
        <div className="col-4 text-platform">
            <img src={platform} className="logo-platform img-fluid" />
            <div className="">powered by the </div>
            <p />
            <div>SmartAI Generative Platform</div>
          </div>
  );


  return (
    <footer className="pt-3 bg-navbar">
      <div className="container">

        <div className="row text-center text-white">    
        <div className="col-4">
          {StockImageLogo}
          
        </div>
       
          <div className="col-4">
            <div className="row text-center text-md-start row-cols-1 row-cols-md-2 gy-2">
              <div className="col"><Link className="footer-link" to="/search?q=random">Explore</Link></div>
              <div className="col"><Link className="footer-link" to="/pricing">Pricing</Link></div>
              <div className="col"><Link className="footer-link" to="/about">About Us</Link></div>
              <div className="col"><Link className="footer-link" to="/categories">Categories</Link></div>
              <div className="col"><a className="footer-link" href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce">Terms of Use</a></div>
              <div className="col"><a className="footer-link" href="https://app.termly.io/document/privacy-policy/d7cbc9ff-588c-4a7d-962f-f8c5e4913d5e">Privacy Policy</a></div>
            </div>
          </div>
          
          {SmartAIPlatformLogo}
        </div>

        <div className="mt-2 text-white-thin text-center">
          Copyright Smart AI Technologies 2023
        </div>
      </div>
    </footer>
  );
}

export default Footer;
