import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";
import {
  TwitterIcon,
  EmailIcon,
  FacebookIcon,
  PinterestIcon,
} from "react-share";
import ImageService from "../../services/image.service";
import ListService from "../../services/list.service";
import ProductService from "../../services/product.service";
import Modal from "../../components/modal/modal";
import { useAuth } from "../../services/auth/use-auth";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Home from "@mui/icons-material/Home";
import TimeAgo from "react-timeago";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { orange } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions,
  Avatar,
  IconButton,
  Divider,
  Backdrop,
  Box,
  CircularProgress,
  FormGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  Link,
  FormLabel,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Chip,
} from "@mui/material";

import "./ImageDetail.css";
import UserService from "../../services/user.service";
import { Label } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
//import { HorizontalRule } from "@mui/icons-material";
//import { Typography } from "@mui/material";

export default function ImageDetail() {
  const auth = useAuth();
  const [data, setData] = useState(null);
  const [varyData, setVaryData] = useState(null);
  const navigate = useNavigate();
  //const [prices, setPrices] = useState(null);
  const [price, setImagePrice] = useState(0);
  const [varyPrice, setVaryPrice] = useState(0);
  const [subscription, setSubscription] = useState(null);
  const [categories, setCategories] = useState(null);
  const [resolutionWidth, setResolutionWidth] = useState(0);
  const [resolutionHeight, setResolutionHeight] = useState(0);
  const [selectedWidth, setSelectedWidth] = useState(0);
  const [selectedHeight, setSelectedHeight] = useState(0);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setSelectedProduct] = useState(null);
  const [selectedImageSize, setSelectedImageSize] = useState(null);
  const [loading, setLoading] = useState(false);

  const [searchParam] = useSearchParams();
  const identifier = searchParam.get("id");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalVaryOpen, setModalVaryOpen] = useState(false);

  // download image from bucket
  const download = async (url) => {
    // console.log("downloadImage: " + JSON.stringify(data));
    var urlDownload = url ? url : data.public_url_reduced;
    const blob = await fetch(urlDownload).then((r) => r.blob());
    const urlBlob = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = urlBlob;
    link.target = "_blank";
    link.download = 'SmartAI-StockImages_' + urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
    link.click();
  };


  const varyImage = async () => {
    // console.log("varyImage(): " + identifier);
    // console.log("varyImage(): auth.user " + auth.user);
    // console.log("varyImage(): auth.user.id " + auth.user.uid);
    // console.log("varyImage(): acceptTerms " + acceptTerms);
    // console.log("varyImage(): product " + JSON.stringify(product));
    // console.log("varyImage(): productId " + product.productId);
    // console.log("varyImage(): # credits " + product.creditsPrice);

    //if (auth.user && acceptTerms && auth.user.id && identifier !== "") {
    if (auth.user && acceptTerms && identifier !== "") {
      // } && product && product.productId > 0) {
      // console.log(
      //   "varyImage() - Passed  check: id #",
      //   identifier,
      //   ",user: ",
      //   JSON.stringify(auth.user)
      // );
      var userId = auth.user.uid;

      const response = await ProductService.VaryImageUsingCredits(
        userId,
        product.productId,
        identifier
      );

      if (response.success === false) {
        alert("VaryImage failed: " + response.message);
        // console.log("VaryImage: " + response);
      } else {
        setVaryData(response);
        // console.log("VaryImage: response: " + JSON.stringify(response));
        // console.log("downloadImage: " + response.product.downloadUrl);
        // console.log("downloadImage: " + response.product.downloadUrl);
        // console.log("downloadImage: " + response.product.assetUrl);
        //downloadImage(response.product.downloadUrl);
        //download(response.product.downloadUrl);
        //navigate("/profile");
      }
    }
  };
  const purchaseImage = async (id) => {
    /*
    // console.log("purchaseImage(): " + identifier);
    // console.log("purchaseImage(): auth.user " + auth.user);
    // console.log("purchaseImage(): auth.user.id " + auth.user.uid);
    // console.log("purchaseImage(): acceptTerms " + acceptTerms);
    // console.log("purchaseImage(): product " + JSON.stringify(product));
    // console.log("purchaseImage(): productId " + product.productId);
    // console.log("purchaseImage(): # credits " + product.creditsPrice);
*/
    setLoading(true);

    //if (auth.user && acceptTerms && auth.user.id && identifier !== "") {
    if (
      auth.user &&
      acceptTerms &&
      identifier !== "" &&
      product &&
      product.productId > 0
    ) {
      // console.log(
      //   "purchaseImage() - Passed  check: id #",
      //   identifier,
      //   ", product #",
      //   product.productId,
      //   " user: ",
      //   JSON.stringify(auth.user)
      // );
      var userId = auth.user.uid;

      const response = await ProductService.PurchaseImageUsingCredits(
        userId,
        product.productId,
        identifier
      );

      if (response.success === false) {
        alert("Purchase failed: " + response.message);
        // console.log("purchaseImage: " + response);
      } else {
        // console.log("downloadImage: " + response.product.downloadUrl);
        // console.log("downloadImage: " + response.product.assetUrl);
        //downloadImage(response.product.downloadUrl);
        download(response.product.downloadUrl);
        navigate("/profile");
      }
    }
    setLoading(false);
  };

  const getResolutionText = (index) => {
    // console.log("getResolutionText: " + index);
    var buffer = `${resolutionWidth}w x ${resolutionHeight}h`;
    switch (index) {
      case 1: {
        buffer = `${resolutionWidth}w x ${resolutionHeight}h`;
        break;
      }
      case 2: {
        buffer = `${resolutionWidth / 2}w x ${resolutionHeight / 2}h`;
        break;
      }
      case 0: {
        buffer = `${resolutionWidth * 4}w x ${resolutionHeight * 4}h`;
        break;
      }
      default: {
        buffer = `${resolutionWidth}w x ${resolutionHeight}h`;
        break;
      }
    }

    if (
      !productExists(
        getResolutionWidth(index),
        getResolutionHeight(index),
        "download"
      )
    ) {
      buffer = buffer + " - N/A";
    }
    // console.log("getResolutionText: " + buffer);
    return buffer;
  };
  const getResolutionWidth = (index) => {
    if (index === 1) {
      return resolutionWidth;
    } else if (index === 2) {
      return resolutionWidth / 2;
    } else if (index === 0) {
      return resolutionWidth * 4;
    } else {
      return resolutionWidth;
    }
  };
  const getResolutionHeight = (index) => {
    if (index === 1) {
      return resolutionHeight;
    } else if (index === 2) {
      return resolutionHeight / 2;
    } else if (index === 0) {
      return resolutionHeight * 4;
    } else {
      return resolutionHeight;
    }
  };

  const productExists = (width, height, action) => {
    // console.log("productExists: ", width, height);
    if (products) {
      if (action === "download") {
        let product = products.find((p) => p.name === `${width}x${height}`);
        if (product) {
          // console.log("productExists: " + product);
          return true;
        }
      } else if (action === "vary") {
        return true;
      }
    }

    return false;
  };

  const getSubscriptionCreditsRemaining = () => {
    if (subscription) {
      return subscription.numSubscriptionCreditsRemaining;
    }
    return 0;
  };
  const getWalletCreditsRemaining = () => {
    if (subscription) {
      return subscription.numWalletCreditsRemaining;
    }
    return 0;
  };

  const getResolutionPrice = (width, height) => {
    // console.log("getResolutionPrice: ", width, height);
    if (!products) {
      getProducts();
    }

    if (products) {
      // console.log("ResolutionWidth: " + width);
      // console.log("ResolutionHeight: " + height);

      let product = products.find((p) => p.name === `${width}x${height}`);
      if (product) {
        // console.log("Product: " + product);
        // console.log(
        //   "Product: " + product.productId,
        //   " is " + product.creditsPrice,
        //   " credits"
        // );
        setSelectedProduct(product);
        setImagePrice(product.creditsPrice);
      } else {
        // console.log("Product not found");
      }
    }
  };

  const handleSelection = async (event) => {
    // console.log("handleSelection");
    // console.log(event.target);
    // console.log(event.target.checked);
    // console.log(event.target.name);

    //var currPrice = price;

    switch (event.target.value) {
      case "Original Size": {
        // console.log("selectionOriginal");
        if (event.target.checked) {
          getResolutionPrice(resolutionWidth, resolutionHeight);
          // console.log("New price:", price);
          setSelectedWidth(resolutionWidth);
          setSelectedHeight(resolutionHeight);
        }
        break;
      }

      case "Half Size": {
        // console.log("selectionHalf");
        if (event.target.checked) {
          getResolutionPrice(resolutionWidth / 2, resolutionHeight / 2);
          // console.log("New price:", price);
          setSelectedWidth(resolutionWidth / 2);
          setSelectedHeight(resolutionHeight / 2);
        }
        break;
      }

      case "Upscale 4x": {
        // console.log("selectionUpscale4x");

        if (event.target.checked) {
          getResolutionPrice(resolutionWidth * 4, resolutionHeight * 4);
          // console.log("New price:", price);
          setSelectedWidth(resolutionWidth * 4);
          setSelectedHeight(resolutionHeight * 4);
        }
        break;
      }
      default: {
        // console.log("selectionOriginal");
        if (event.target.checked) {
          getResolutionPrice(resolutionWidth * 4, resolutionHeight * 4);
          setSelectedWidth(resolutionWidth);
          setSelectedHeight(resolutionHeight);
        }
      }
    }

    //setImagePrice(currPrice);
  };

  /*
  const getImagePrice = (width, height) => {
    if (prices) {
      const price = prices.find((price) => {
        return price.width === width && price.height === height;
      })
      return price.price;
    }
  };
*/
  const getProducts = async () => {
    // get products
    const productResponse = await ProductService.GetProductList();
    // console.log("Products:", productResponse);
    setProducts(productResponse);
  };

  const getImageDetails = async () => {
    // get image and image details
    // console.log("Getting image details for id:", identifier);
    const response = await ImageService.GetImageByIdentifier(identifier);
    // console.log(
    //   "Image id:",
    //   identifier,
    //   "width:",
    //   response.width,
    //   " height:",
    //   response.height
    // );
    setResolutionHeight(response.height);
    setResolutionWidth(response.width);
    setData(response);
  };

  useEffect(() => {
    const fetchData = async () => {
      getProducts();

      getImageDetails();

      getResolutionPrice(resolutionWidth, resolutionHeight);
    };

    const getCategories = async () => {
      const response = await ListService.GetCategories();
      setCategories(response);
    };

    const getSubscription = async () => {
      if (auth.user) {
        const response = await UserService.GetUserCredits(auth.user.uid);
        // console.log(
        //   "Logged in, subscription info for auth.user.id:",
        //   auth.user.uid,
        //   "\n",
        //   response
        // );
        setSubscription(response.creditProfile);
        // console.log("CreditProfile: ", JSON.stringify(response.creditProfile));
      } else {
        //const response = await UserService.GetUserCredits("User123");
        var credit = {
          creditProfile: {
            numSubscriptionCreditsRemaining: -1,
            numWalletCreditsRemaining: -1,
            numPendingCredits: -1,
          },
        };
        setSubscription(credit);
        // console.log("No Subscription - Not Logged in ", JSON.stringify(credit));
      }
    };

    // {data.title.substring(0, 100) + "..."}
    getSubscription();
    //getPrices();
    getCategories();

    fetchData();
  }, [identifier]);

  const getVaryButtonText = () => {
    if (auth.user) {
      // check if image size is available in user's plan
      if (selectedWidth > 0 && selectedHeight > 0) {
        if (productExists(selectedWidth, selectedHeight, "vary")) {
          return `Vary ${selectedWidth}x${selectedHeight} ( ${varyPrice} Credits )`;
        } else {
          return "Vary not included in your plan";
        }
      }
    }
  };

  const getVaryUrl = () => {
    if (auth.user) {
      if (varyData) {
        // console.log("getVaryUrl: ", JSON.stringify(varyData));
        return varyData.ImageUrls[0];
      } else {
        return "";
      }
    }
  };

  const getDownloadButtonText = () => {
    // authenticated user
    /*
    // console.log("getDownloadButtonText: user authenticated:", auth.user.uid)
    // console.log("getDownloadButtonText: selectedWidth:", selectedWidth)
    // console.log("getDownloadButtonText: selectedHeight:", selectedHeight)
    // console.log("getDownloadButtonText: resolutionWidth:", resolutionWidth)
    // console.log("getDownloadButtonText: resolutionHeight:", resolutionHeight)
    // console.log(`getDownloadButtonText: productExists(${selectedWidth}, ${selectedHeight}):", ${productExists(selectedWidth, selectedHeight)} `);
*/

    if (auth.user) {
      // check if image size is available in user's plan
      if (selectedWidth > 0 && selectedHeight > 0) {
        if (productExists(selectedWidth, selectedHeight, "download")) {
          return `Download Image ( ${price} Credits )`;
          //return `Download ${selectedWidth}x${selectedHeight} ( ${price} Credits )`;
        } else {
          return `Resolution ${resolutionWidth}x${resolutionHeight} is not included in your plan`;
        }
      } // authenticated but nothign selected
      else {
        if (productExists(resolutionWidth, resolutionHeight, "download")) {
          return "Please select an image size.";
        } else {
          return `Resolution ${resolutionWidth}x${resolutionHeight} not included in your plan`;
        }
      }
    } else {
      // not authenticated user
      return "Login/Join to download images";
    }
  };

  const inputTermsPrivacy = (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={acceptTerms}
            onChange={(e, c) => setAcceptTerms(c)}
          />
        }
        label={
          <Typography>
            I agree to the{" "}
            <Link
              target="_blank"
              href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
            >
              terms &amp; conditions
            </Link>
          </Typography>
        }
      />
    </FormGroup>
  );

  const calculateRemainingSubscriptionCreditsAfterPurchase = (numCredits) => {
    if (subscription) {
      if (subscription.numSubscriptionCreditsRemaining > numCredits) {
        return subscription.numSubscriptionCreditsRemaining - numCredits;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  const calculateRemainingWalletCreditsAfterPurchase = (numCredits) => {
    if (subscription) {
      if (subscription.numSubscriptionCreditsRemaining > numCredits) {
        return subscription.numWalletCreditsRemaining;
      } else {
        return subscription.numWalletCreditsRemaining - numCredits;
      }
    }
  };

  const ImageCard = data ? (
    <Card className="" sx={{}}>
      <CardHeader
        title={
          <div className="">
          <Chip
            color="secondary"
            label={<Typography>{" " + data.category + " "}</Typography>}
          />
          <Typography variant="h6" color="text.primary">
            {data.title}
          </Typography>
        </div>
        }
        action={<IconButton aria-label="settings"></IconButton>}
      >
        
      </CardHeader>

      <CardMedia
        className="img-responsive img-thumbnail"
        component="img"
        image={data.public_url_protected}
        alt={data.description}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          <div>
            Uploaded by {data.user_id} &nbsp;
            <TimeAgo date={data.generated_timestamp} />
          </div>
          <br />
          <div className="fw-bold mb-1">Description</div>
          <div className="my-2">{data.description}</div>
          <div>
            <span className="fw-bold mb-1">generated by:</span>
            <span className="mb-1"> {data.generated_by}</span>
          </div>
          <div>
            <span className="fw-bold mb-1">prompted by:</span>
            <span className="mb-1"> {data.prompted_by}</span>
          </div>
        </Typography>
        <br />
        {/* <div className="text-align-right">
          <FacebookIcon size={32} round={true} />
          <TwitterIcon size={32} round={true} />
          <PinterestIcon size={32} round={true} />
          <EmailIcon size={32} round={true} />
          <FacebookShareButton />
        </div> */}
      </CardContent>
    </Card>
  ) : (
    <div></div>
  );

  const ImageCardConfirm = data ? (
    <Card className="img-wrapper" sx={{}}>
      <CardHeader
        avatar={
          <Chip
            color="secondary"
            label={<Typography>{" " + data.category + " "}</Typography>}
          />
        }
        action={<IconButton aria-label="settings"></IconButton>}
        title={
          <Typography variant="body2" color="text.primary">
            {data.title}
          </Typography>
        }
      ></CardHeader>

      <CardMedia
        className="img-responsive img-thumbnail"
        component="img"
        image={data.public_url_protected}
        alt={data.description}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          <div>
            Uploaded by {data.user_id} &nbsp;
            <TimeAgo date={data.generated_timestamp} />
          </div>
          <br />
          <div className="fw-bold mb-1">Description</div>
          <div className="my-2">{data.description}</div>
          <div className="">
            <p />
            <ul>
              <li className="ms-1">Cost: {price} credit(s)</li>
              <li className="ms-1">
                Current Monthly Credits:{" "}
                {subscription.numSubscriptionCreditsRemaining}
              </li>
              <li className="ms-1">
                # Monthly Credits After Purchase:{" "}
                {calculateRemainingSubscriptionCreditsAfterPurchase(price)}
              </li>
              <li className="ms-1">
                # Wallet Credits Post Purchase:{" "}
                {calculateRemainingWalletCreditsAfterPurchase(price)}
              </li>
            </ul>
          </div>
        </Typography>
        <br />
      </CardContent>
    </Card>
  ) : (
    <div></div>
  );

  const ImageCardVary = data ? (
    <Card className="img-wrapper" sx={{}}>
      <CardHeader
        avatar={
          <Card sx={{}} aria-label="recipe">
            <Typography color="text.secondary">{data.category}</Typography>
          </Card>
        }
        action={<IconButton aria-label="settings"></IconButton>}
        title={data.title}
      ></CardHeader>

      <CardMedia
        className="img-responsive img-thumbnail"
        component="img"
        image={data.public_url_protected}
        alt={data.description}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          <div>
            Uploaded by {data.user_id} &nbsp;
            <TimeAgo date={data.generated_timestamp} />
          </div>
          <br />
          <div className="fw-bold mb-1">Description</div>
          <div className="my-2">{data.description}</div>
          <div className="">
            <p />
            <ul>
              <li className="ms-1">Cost: {varyPrice} credit(s)</li>
              <li className="ms-1">
                Current Monthly Credit Balance:{" "}
                {subscription.numSubscriptionCreditsRemaining}
              </li>
              <li className="ms-1">
                # Monthly Credits After Purchase:{" "}
                {calculateRemainingSubscriptionCreditsAfterPurchase(varyPrice)}
              </li>
              <li className="ms-1">
                # Wallet Credits After Purchase:{" "}
                {calculateRemainingWalletCreditsAfterPurchase(varyPrice)}
              </li>
            </ul>
          </div>
        </Typography>
        <br />
      </CardContent>
    </Card>
  ) : (
    <div></div>
  );

  return (
    <>
      {data ? (
        <div className="mb-3 ">
          {modalOpen && (
            <Modal 
              setOpenModal={setModalOpen} 
              title="Confirm Download"
              portalClassName="modal-container"
              headerClassName="modal-header"
              bodyClassName="modal-body"
            >
              <div className="d-flex flex-fill align-items-center modal-container">
                <div className="img-wrapper ">
                  {ImageCardConfirm}

                  <div className="mt-1">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {inputTermsPrivacy}
                    </Box>
                  </div>
                </div>
              </div>

              <p />
              <div className="d-flex flex-fill align-items-center">
                <div className="text-center mx-auto">
                  <i className="fa-solid fa-wallet"></i>
                  <span className="ms-2">
                    Monthly Credits Balance:{" "}
                    {subscription.numSubscriptionCreditsRemaining}
                  </span>
                  <br />
                  <span className="mb-3 ms-2">
                    Wallet Credits: {subscription.numWalletCreditsRemaining}
                  </span>
                  <br />
                  <LoadingButton
                    className="mt-3"
                    variant="contained"
                    disabled={!acceptTerms || loading}
                    loading={loading}
                    onClick={() => {
                      purchaseImage(data.identifier);
                      // console.log("clicked modal");
                    }}
                  >
                    Download ( {price} Credits )
                  </LoadingButton>
                </div>
              </div>

              <div className="bg-banner my-3 px-4 text-center ">
                <div className="d-flex align-items-center justify-content-center mx-auto py-2">
                  <p className="mb-0 me-3 size-options-text">Need Credits?</p>
                  <Link to="/pricing"
                    className="rounded-pill"
                    variant="contained"                    
                  >
                    Get Credits
                  </Link>
                </div>
              </div>
            </Modal>
          )}

          {modalVaryOpen && (
            <Modal setOpenModal={setModalVaryOpen} title="Vary Image">
              <div className="d-flex flex-fill align-items-center">
                <div className="img-wrapper ">
                  {ImageCardVary}

                  <div className="mt-3 col-12 col-md-6">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {inputTermsPrivacy}
                    </Box>
                  </div>
                </div>
              </div>
              <p />
              <p />
              <div className="d-flex flex-fill align-items-center">
                <div className="text-center mx-auto">
                  <i className="fa-solid fa-wallet"></i>
                  <span className="ms-2">
                    Monthly Credits Balance:{" "}
                    {subscription.numSubscriptionCreditsRemaining}
                  </span>
                  <span className="ms-2">
                    Wallet Credits: {subscription.numWalletCreditsRemaining}
                  </span>
                  <LoadingButton
                    variant="contained"
                    disabled={!acceptTerms || loading}
                    loading={loading}
                    onClick={() => {
                      varyImage(data.identifier);
                      // console.log("clicked modal");
                    }}
                  >
                    Use AI Vary Image ( {varyPrice} Credits )
                  </LoadingButton>
                </div>
              </div>
              <div className="bg-banner my-3 px-4 text-center ">
                <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
                  <p className="mb-0 me-3 text-white">Need Credits?</p>
                  <Button
                    variant="contained"
                    onClick={() => {
                      navigate("/pricing");
                    }}
                  >
                    Get Credits
                  </Button>
                </div>
              </div>
            </Modal>
          )}

          <p />
          <div className="container">
            <div className="row px-5 gx-5 gy-2">
              <div className="col"></div>
              <div className="col-12 col-md-6"></div>

              <div className="col-12 col-md-6">{ImageCard}</div>

              <div className="col-12 col-md-6">
                <Card variant="outline" sx={{}}>
                  <h3 className="fw-bold">{price} Credit(s)</h3>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      mt: 7,
                    }}
                  >
                    <FormControl
                      sx={{ margin: "auto", maxWidth: "80%" }}
                      variant="standard"
                    >
                       {( productExists(
                                  getResolutionWidth(1),
                                  getResolutionHeight(1),
                                  "download"
                                )) ? (
                        <FormLabel>                     
                          <Typography variant="h5" fontWeight="5">
                            Select an Image Size
                          </Typography>
                          </FormLabel>
                        ) : (
                        <FormLabel>
                          <Typography variant="h5" fontWeight="5">
                          No Image Sizes Available
                          </Typography>
                        </FormLabel>
                      )}

                     
                      <Divider sx={{ my: 1 }} />
                      <RadioGroup
                        name="imageSize"
                        value={selectedImageSize}
                        onChange={(e, v) => setSelectedImageSize(v)}
                      >
                        <FormControlLabel
                          value="Upscale 4x"
                          control={
                            <Radio
                              hidden={true}
                              onChange={handleSelection}
                              value="Upscale 4x"
                              label={`Upscale 4x (${getResolutionText(0)})`}
                              disabled={
                                !productExists(
                                  getResolutionWidth(0),
                                  getResolutionHeight(0),
                                  "download"
                                )
                              }
                            />
                          }
                          label={
                            <Typography
                              variant="body4"
                              hidden={true}
                              sx={{
                                fontSize: productExists(
                                  getResolutionWidth(0),
                                  getResolutionHeight(0),
                                  "download"
                                )
                                  ? "1.25rem"
                                  : "1.00rem",
                                  textDecoration: productExists(
                                    getResolutionWidth(0),
                                    getResolutionHeight(0),
                                    "download"
                                  )
                                    ? ""
                                    : "line-through",
                              }}
                            >
                              Upscale 4x {getResolutionText(0)}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="Original Size"
                          control={
                            <Radio
                              overlay
                              onChange={handleSelection}
                              value="Original Size"
                              disabled={
                                !productExists(
                                  getResolutionWidth(1),
                                  getResolutionHeight(1),
                                  "download"
                                )
                              }
                            />
                          }
                          label={
                            <Typography
                              variant="h6"                              
                              sx={{
                                fontSize: productExists(
                                  getResolutionWidth(1),
                                  getResolutionHeight(1),
                                  "download"
                                )
                                  ? "1.25rem"
                                  : "1.00rem",
                                  textDecoration: productExists(
                                    getResolutionWidth(1),
                                    getResolutionHeight(1),
                                    "download"
                                  )
                                    ? ""
                                    : "line-through",
                              }}
                            >
                              Original Size {getResolutionText(1)}
                            </Typography>
                          }
                        />
                        <FormControlLabel
                          value="Half Size"
                          control={
                            <Radio
                              overlay
                              hidden={true}
                              onChange={handleSelection}
                              value="Half Size"
                              label={`Half Size (${getResolutionText(2)})`}
                              disabled={
                                !productExists(
                                  getResolutionWidth(2),
                                  getResolutionHeight(2),
                                  "download"
                                )
                              }
                            />
                          }
                          label={
                            <Typography
                              variant="h6"
                              hidden={true}    
                              sx={{
                                fontSize: productExists(
                                  getResolutionWidth(2),
                                  getResolutionHeight(2),
                                  "download"
                                )
                                  ? "1.25rem"
                                  : "1.00rem",
                                  textDecoration: productExists(
                                    getResolutionWidth(2),
                                    getResolutionHeight(2),
                                    "download"
                                  )
                                    ? ""
                                    : "line-through",
                              }}
                            >
                              Half Size {getResolutionText(2)}
                            </Typography>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>

                  <br />
                  <div className="d-flex flex-fill align-items-center">
                    <div className="text-center mx-auto">
                      <i className="fa-solid fa-coins"></i>
                      <span className="ms-2">
                        Monthly Credits Balance:{" "}
                        {getSubscriptionCreditsRemaining()}
                      </span>
                      <br />
                      <span className="ms-2">
                        Wallet Credits: {getWalletCreditsRemaining()}
                      </span>
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <LoadingButton
                          variant="contained"
                          className="button mt-1"
                          disabled={
                            !product ||
                            !auth.user ||
                            selectedHeight === 0 ||
                            selectedWidth === 0 ||
                            loading
                          }
                          loading={loading}
                          onClick={() => {
                            if (auth.user) {
                              setModalOpen(true);
                              // console.log("clicked modal");
                            } else {
                              // console.log("sent to subscription screen");
                              // TODO:: navigate to subscription screen
                              //navigate('/signup');
                              setModalOpen(true);
                            }
                          }}
                        >
                          {getDownloadButtonText()}
                        </LoadingButton>
                        <Button
                          variant="contained"
                          hidden={varyPrice === 0}
                          disabled={
                            !product ||
                            !auth.user ||
                            selectedHeight === 0 ||
                            selectedWidth === 0
                          }
                          onClick={() => {
                            if (auth.user) {
                              setModalVaryOpen(true);
                            } else {
                              // console.log("sent to subscription screen");
                              // TODO:: navigate to subscription screen
                              //navigate('/signup');
                              setModalOpen(true);
                            }
                          }}
                        >
                          {getVaryButtonText()}
                        </Button>

                        <Button
                          className="mt-4 rounded-pill"
                          hidden={ !((!product &&
                            auth.user) &&
                            (selectedHeight === 0 &&
                            selectedWidth === 0)
                          )}
                          variant="contained"
                          onClick={() => {
                            navigate("/pricing");
                          }}
                        >
                          More Options? View Plans
                        </Button>
                      </Box>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="col-12">
                <div className="mt-3 mb-5">
                  <div className="fw-bold mb-2">Topics:</div>
                  {data.tags
                    .filter((tag) => {
                      return tag.split(":")[0] === "topic";
                    })
                    .map((tag) => {
                      return (
                        <Chip
                          color="secondary"
                          sx={{ margin: 0.2 }}
                          label={
                            <Typography
                              sx={{
                                margin: 1,
                                fontFamily: "Raleway",
                                fontSize: 12,
                              }}
                            >
                              {tag.split(":")[1]}
                            </Typography>
                          }
                        />
                        // <Card
                        //   variant="outlined"
                        //   sx={{ width: 180, height: 50 }}
                        // >
                        //   <CardContent className="text-center">
                        //     <Typography
                        //       sx={{ fontFamily: "Raleway", fontSize: 12 }}
                        //       variant="body2"
                        //     >
                        //       {tag.split(":")[1]}
                        //     </Typography>
                        //   </CardContent>
                        // </Card>
                      );
                    })}

                  <div className="mt-2 fw-bold mb-2">Tags:</div>
                  <div className="d-flex flex-row flex-wrap">
                    {data.tags
                      .filter((tag) => {
                        return (
                          tag.split(":")[0] !== "topic" &&
                          tag.split(":")[0] !== "category" &&
                          tag.includes(":")
                        );
                      })
                      .map((tag) => {
                        return (
                          <Chip
                            sx={{ margin: 0.2 }}
                            label={
                              <Typography
                                sx={{
                                  margin: 1,
                                  fontFamily: "Raleway",
                                  fontSize: 12,
                                }}
                              >
                                {tag.split(":")[1]}
                              </Typography>
                            }
                          />
                          // <Card
                          //   variant="outlined"
                          //   sx={{ width: 180, height: 50 }}
                          // >
                          //   <CardContent className="text-center">
                          //     <Typography
                          //       sx={{ fontFamily: "Raleway", fontSize: 12 }}
                          //     >
                          //       {tag.split(":")[1]}
                          //     </Typography>
                          //   </CardContent>
                          // </Card>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-banner my-3 px-4 text-center ">
            <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
              <p className="mb-0 me-3 size-options-text">Want more size options?</p>
              <Button
                className="rounded-pill"
                variant="contained"
                onClick={() => {
                  navigate("/pricing");
                }}
              >
                View Plans
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
    </>
  );
}
