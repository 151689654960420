import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import ImageService from "../../services/image.service";
import ProductService from "../../services/product.service";
import ContentPackService from "../../services/contentpack.service";
import {
  Input,
  TextField,
  FormControl,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import InputMask from "react-input-mask";
import { IMask, IMaskInput } from "react-imask";
import Tiles from "../../components/Tiles/Tiles";
import Modal from "../../components/modal/modal";
import AuthorizeNetLogo from "../../assets/img/authorize-net-visa-logo.jpg";

import { useAuth } from "../../services/auth/use-auth";

import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

import {
  TwitterIcon,
  EmailIcon,
  FacebookIcon,
  PinterestIcon,
} from "react-share";

import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import TimeAgo from "react-timeago";
import UserService from "../../services/user.service";
import { Chip, Typography } from "@mui/material";
import {
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions,
  Avatar,
  IconButton,
} from "@mui/material";
import "./Pack.css";
import { LoadingButton } from "@mui/lab";

export default function Pack() {
  const auth = useAuth();
  //const [{ isPending }] = usePayPalScriptReducer();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  //const [prices, setPrices] = useState(null);
  const [price, setImagePrice] = useState(0);
  const [subscription, setSubscription] = useState(null);
  //const [categories, setCategories] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [products, setProducts] = useState([]);
  const [product, setSelectedProduct] = useState(null);
  const [packImages, setImages] = useState([]);
  const [cardNumber, setCardNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingCountryRegion, setBillingCountryRegion] =
    useState("United States");
  const [billingState, setBillingState] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingPhoneNumber, setBillingPhoneNumber] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState("USD");
  const [loading, setLoading] = useState(false);
  //const[creditCardCompany, setCreditCardCompany] = useState("");
  //const[creditCardTaxId, setCreditCardTaxId] = useState("");

  const [searchParam] = useSearchParams();
  const packId = searchParam.get("id");
  // console.log("packId", packId);

  const [modalOpen, setModalOpen] = useState(false);

  // download image from bucket
  /*
  const downloadBundle = (url) => {
    // console.log("downloadBundle: " + url);

    var urlDownload = url ? url : data.pack_bundle_url;

    const link = document.createElement("a");
    link.href = urlDownload;
    link.target = "_blank";
    link.setAttribute("download", `smartai-cp-${packId}`); //or any other extension
    document.body.appendChild(link);
    link.click();
  };
*/

  const downloadPackZip = async (url) => {
    // console.log("downloadImage: " + JSON.stringify(data));
    var urlDownload = url ? url : data.pack_bundle_url;
    const blob = await fetch(urlDownload).then((r) => r.blob());
    const urlBlob = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = urlBlob;
    link.target = "_blank";
    link.download =
      "SmartAI-StockImages_" +
      urlDownload.substr(urlDownload.lastIndexOf("/") + 1);
    link.click();
  };

  const getPriceString = () => {
    if (data) {
      var priceFormatted = data.price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });

      return priceFormatted + " USD";
    } else {
      return (priceFormatted = "0.00 USD");
    }
  };
  const getNumImages = () => {
    if (packImages) {
      return packImages.length + " images";
    } else {
      return "0 images";
    }
  };

  const checkLuhn = (cardnumber) => {
    // Build an array with the digits in the card number
    var digits = cardnumber.split("");
    for (var i = 0; i < digits.length; i++) {
      digits[i] = parseInt(digits[i], 10);
    }
    // Run the Luhn algorithm on the array
    var sum = 0;
    var alt = false;
    for (i = digits.length - 1; i >= 0; i--) {
      if (alt) {
        digits[i] *= 2;
        if (digits[i] > 9) {
          digits[i] -= 9;
        }
      }
      sum += digits[i];
      alt = !alt;
    }
    // Check the result
    if (sum % 10 === 0) {
      // console.log("Luhn check passed");
      return true;
    } else {
      // console.log("Luhn check failed");
      return false;
    }
  };

  const validateCC = () => {
    // console.log("validateCC - checking");
    let error = "";

    if (cardNumber && cardNumber.length === 16) {
      if (checkLuhn(cardNumber)) {
        if (
          expirationDate &&
          (expirationDate.length === 4 || expirationDate.length === 5)
        ) {
          if (cvv && (cvv.length === 3 || cvv.length === 4)) {
            if (billingCountryRegion) {
              if (billingState) {
                if (billingCity) {
                  if (billingAddress) {
                    if (billingPostalCode) {
                      if (firstName) {
                        if (lastName) {
                          var card = {
                            creditCardNumber: cardNumber,
                            expirationDateString: expirationDate,
                            cvv: cvv,
                            countryRegion: billingCountryRegion,
                            state: billingState,
                            city: billingCity,
                            addressLine1: billingAddress,
                            addressLine2: billingAddress2,
                            postalCode: billingPostalCode,
                            phoneNumber: billingPhoneNumber,
                            email: billingEmail,
                            firstName: firstName,
                            lastName: lastName,
                          };
                          // console.log("validateCC(): card number is valid");
                          return card;
                        } else error = "invalid last name";
                      } else error = "invalid first name";
                    } else error = "invalid postal code)";
                  } else error = "invalid address";
                } else error = "invalid city";
              } else error = "invalid state";
            } else error = "invalid country";
          } else error = "invalid cvv";
        } else error = "invalid expiration date";
      } else {
        error = "card number is invalid - number check failed";
        //return null;
      }
    } else error = "card number is invalid - not enough digits";

    // console.log("validateCC(): " + error);
    alert("Failed credit card validation: " + error);
    return null;
  };

  /*
  const validateCC2 = () => {
    // console.log("validateCC - checking");
    if (cardNumber && cardNumber.length === 16) {
      if (
        expirationDate &&
        (expirationDate.length === 4 || expirationDate.length === 5)
      ) {
        if (cvv && (cvv.length === 3 || cvv.length === 4)) {
          if (billingCountryRegion) {
            if (billingState) {
              if (billingCity) {
                if (billingAddress) {
                  if (billingPostalCode) {
                    if (firstName) {
                      if (lastName) {
                        var card = {
                          creditCardNumber: cardNumber,
                          expirationDateString: expirationDate,
                          cvv: cvv,
                          countryRegion: billingCountryRegion,
                          state: billingState,
                          city: billingCity,
                          addressLine1: billingAddress,
                          addressLine2: billingAddress2,
                          postalCode: billingPostalCode,
                          phoneNumber: billingPhoneNumber,
                          email: billingEmail,
                          firstName: firstName,
                          lastName: lastName,
                        };

                        if (checkLuhn(card.creditCardNumber)) {
                          // console.log("validateCC(): card number is valid");
                          return card;
                        } else {
                          // console.log("validateCC(): card number is invalid");
                          return null;
                        }
                      } else // console.log("validateCC(): missing last name");
                    } else // console.log("validateCC(): missing first name");
                  } else // console.log("validateCC(): missing postal code");
                } else // console.log("validateCC(): missing address");
              } else // console.log("validateCC(): missing city");
            } else // console.log("validateCC(): missing state");
          } else // console.log("validateCC(): missing country");
        } else // console.log("validateCC(): missing cvv");
      } else // console.log("validateCC(): missing expiration date");
    } else // console.log("validateCC(): missing card number");

    return null;
  };
*/

  const getCurrentProduct = () => {
    if (product && product.productId) {
      return product;
    } else {
      // retrieve product id from price matching -- assumes once price per product is set -- current assumption
      var contentPacks = products.filter((p) => p.productCategoryId === 2); // filter for content packs
      var packPriced = contentPacks.filter((p) => p.moneyPrice === data.price); // filter for packs with price

      if (packPriced.length >= 1) {
        // if there is a pack with price, select it, if muliple select first
        setSelectedProduct(packPriced[0]); //.productId;
        return packPriced[0];
      }
    }
  };

  const purchasePackPP = async (transactionId) => {
    var product = getCurrentProduct();
    // console.log("purchasePackPP: " + JSON.stringify(product));

    //if (auth.user && acceptTerms && auth.user.id && identifier !== "") {
    if (auth.user && product && product.productId) {
      var userId = auth.user.uid;
      try {
        const response = await ProductService.PurchaseContentPackPP(
          userId,
          product.productId,
          packId,
          data.price,
          transactionId
        );

        if (response.success === false) {
          alert("Purchase failed: " + response.message);
          // console.log("purchasePack: " + response);
        } else {
          alert(
            "Successful purchase - Zip file download will occur now, images will also be moved to your My Images gallery, please allow several minutes for large content packs to transfer."
          );
          // console.log("downloadPack: " + response.product.pack_bundle_url);
          // console.log("downloadPack: " + response.product.assetUrl);
          downloadPackZip(response.product.downloadUrl);
          navigate("/profile");
        } // purchase repsonse check
      } catch (error) {
        alert("Purchase failed: " + error.message);
      }
    } // product purchase check
    else {
      alert("Purchase not attempted: missing user or product");
    }
    setLoading(false)
  };

  const purchasePackViaCC = async () => {
    var product = getCurrentProduct();
    // console.log("purchasePackViaCC: " + JSON.stringify(product));

    //if (auth.user && acceptTerms && auth.user.id && identifier !== "") {
    if (auth.user && acceptTerms && packId && product) {
      /*
      // console.log(
        "purchasePackViaCC() - Passed preconditions: id #",
        packId,
        ", product id:",
        JSON.stringify(product.productId),
        ", product:",
        JSON.stringify(product),
        " acceptTerms " + acceptTerms,
        " user: ",
        JSON.stringify(auth.user.uid)
      );
      */
      var userId = auth.user.uid;
      var email = auth.user.email;

      let creditCardInfo = validateCC();
      if (creditCardInfo) {
        try {
          // console.log(
          //   "purchasePackViaCC(): credit card info is valid, calling purchase api"
          // );
          const response = await ProductService.PurchaseContentPackCC(
            userId,
            email,
            product.productId,
            packId,
            data.price,
            creditCardInfo
          );

          if (response.success === false) {
            alert("Purchase failed: " + response.message);
            // console.log("purchasePackViaCC: " + response);
          } else {
            // console.log("purchasePackViaCC: SUCCESS - response: " + JSON.stringify(response));
            // console.log("purchasePackViaCC: SUCCESS - downloadPack: " + response.product.downloadUrl);
            downloadPackZip(response.product.downloadUrl);
            alert(
              "Successful purchase - Zip file download will occur now, images will also be moved to your My Images gallery, please allow several minutes for large content packs to transfer."
            );
            navigate("/profile");
          } // purchase repsonse check
          setLoading(false);
        } catch (error) {
          alert("Purchase failed: " + error.message);
          setLoading(false);
        }
      } // ccinfo is valid check
      else {
        //alert("Credit card info is not valid");
        setLoading(false);
      }
    } // ccinfo is not null
    else {
      /*
      // console.log(
        "purchasePackViaCC() - Passed  check: id #",
        packId,
        ", product: ",
        JSON.stringify(product),
        " acceptTerms " + acceptTerms,
        " user: ",
        JSON.stringify(auth.user.uid)
      );
      */
      alert("Disallowed purchase - user not logged in or product not allowed");
      setLoading(false);
    }
  };

  const productExists = (packId) => {
    return true;
  };

  const getProducts = async () => {
    // get products
    const productResponse = await ProductService.GetProductList();
    // console.log("Products:", productResponse);
    setProducts(productResponse);
    var selectedProduct = getCurrentProduct();
    setSelectedProduct(selectedProduct);
  };

  /*
  const getImageDetails = async () => {
    // get image and image details
    // console.log("Getting image details for id:", identifier);
    const response = await ImageService.GetImageByIdentifier(identifier);
    // console.log("Image id:", identifier, "width:", response.width, " height:", response.height);
    setResolutionHeight(response.height);
    setResolutionWidth(response.width);
    setData(response);
 };
 */

  const getContentPack = async (packId) => {
    if (packId) {
      const packResponse = await ContentPackService.GetContentPackById(packId);
      setData(packResponse);
      // console.log("fetching content pack response: ", JSON.stringify(packResponse));

      if (packResponse)
        if (packResponse.images) {
          setImages([...packResponse.images]);
        }
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log("handleSubmit");
    var response = purchasePackViaCC();
    // console.log("handleSubmit: " + JSON.stringify(response));
  };

  useEffect(() => {
    const fetchData = async () => {
      getProducts();
      getContentPack(packId);
      //getImageDetails();
      //getResolutionPrice(resolutionWidth, resolutionHeight);
    };

    const getSubscription = async () => {
      if (auth.user) {
        const response = await UserService.GetUserCredits(auth.user.uid);
        setSubscription(response.creditProfile);

        /*
        // console.log(
          "Logged in, subscription info for auth.user.id:",
          auth.user.uid,
          "\n",
          response
        );
        */

        // console.log("CreditProfile: ", JSON.stringify(response.creditProfile));
      } else {
        //const response = await UserService.GetUserCredits("User123");
        var credit = {
          creditProfile: {
            numSubscriptionCreditsRemaining: -1,
            numWalletCreditsRemaining: -1,
            numPendingCredits: -1,
          },
        };
        setSubscription(credit);
        // console.log("No Subscription - Not Logged in ", JSON.stringify(credit));
      }
    };

    // {data.title.substring(0, 100) + "..."}
    getSubscription();
    //getPrices();

    fetchData();
  }, [packId]);

  const getDownloadButtonText = () => {
    if (auth.user) {
      if (productExists(packId)) return "Purchase for " + getPriceString();
      else return "Not available (select another image or upgrade plan)";
    } else return "Login/Join to purchase";
  };

  const getSafeTitle = (title) => {
    if (title) {
      if (title.length <= 50) {
        return title;
      } else {
        return title.substring(0, 50) + "...";
      }
    }
    return "";
  };
  const onPaypalCreateOrder = (paypalData, actions) => {
    // console.log('onPaypalCreateOrder: ' + paypalData);
    // console.log(paypalData)
    if ('paymentSource' in paypalData) {
      if (paypalData.paymentSource !== 'card') {
        setLoading(true);
      }
    }
    // console.log("onPaypalCreateOrder: " + JSON.stringify(paypalData));
    // console.log("onPaypalCreateOrder: " + JSON.stringify(data));

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: data.price,
          },
        },
      ],
    });
  };

  const onPaypalApproveOrder = (data, actions) => {
    setLoading(true);
    // console.log("onPaypalApproveOrder: " + JSON.stringify(data));
    return actions.order.capture().then((details) => {
      //const name = details.payer.name.given_name;
      const transactionId = details.purchase_units[0].payments.captures[0].id;
      // console.log("Paypal Transaction Details: " + JSON.stringify(details));
      // console.log("Calling post paypal purchase workflow");
      setLoading(true);
      purchasePackPP(transactionId);
    });
  };

  /* PAYPAL - PAYMENT */
  const PayPalOrderSection = (
    <PayPalScriptProvider
      options={{
         "client-id": "AYCBsFGtcEQDi1Ya3-Y_-VGVuBDeR8w6hwOGJgY0uh7_Td0V9SErINEmldT19ERsj88ayON_ChmkBSXx", // LIVE
        // "client-id": "AdeF5xY0p5wqpz3dirQW6GYTxAUo674falyk-oZXjTlSXEPzVs3DHC2IhtLA2vEqLgMay4wwNAasWwyZ", // sandbox
        components: "buttons",
      }}
    >
      <PayPalButtons
        style={{ layout: "vertical" }}
        disabled={!acceptTerms}
        createOrder={(data, actions) => onPaypalCreateOrder(data, actions)}
        onApprove={(data, actions) => onPaypalApproveOrder(data, actions)}
        onCancel={(data) => setLoading(false)}
        onError={(err) => {
          alert("There was an error talking with PayPal");
          setLoading(false);
        }}
      />
    </PayPalScriptProvider>
  );

  const CreditCardExpiration = React.forwardRef(function TextMaskCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="MM/YY"
        lazy={true}
        autofix={true}
        overwrite={true}
        onComplete={(value) =>
          onChange({
            target: { name: props.name, value: value.replace("/", "") },
          })
        }
        blocks={{
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
          YY: {
            mask: IMask.MaskedRange,
            from: 23,
            to: 99,
          },
        }}
        inputRef={ref}
      />
    );
  });

  /* CREDIT CARDS - AUTHORIZE.NET */
  const creditCardForm = (
    <FormControl className="" onSubmit={handleSubmit}>
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]* " }}
        disabled={!acceptTerms}
        label="Card Number"
        required={true}
        value={cardNumber}
        //helperText="Enter credit card number"
        onChange={(event) => setCardNumber(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Expiration Date (MM/YY)"
        InputProps={{ inputComponent: CreditCardExpiration }}
        required={true}
        name="expirationDate"
        defaultValue="MM/YY"
        value={expirationDate}
        onChange={(event) => setExpirationDate(event.target.value)}
      >
        {/* <InputMask mask="99/99" maskChar=" " /> */}
      </TextField>
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="CVV (3 or 4 digit code on card)"
        required={true}
        inputProps={{ inputMode: "numeric", pattern: "/^[0-9]{3,4}$/" }}
        value={cvv}
        //helperText="CVV"
        onChange={(event) => setCvv(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Email"
        required={false}
        type="email"
        value={billingEmail}
        //helperText="Email"
        onChange={(event) => setBillingEmail(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="First Name"
        required={true}
        type="text"
        value={firstName}
        //helperText="First Name"
        onChange={(event) => setFirstName(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Last Name"
        required={true}
        type="text"
        value={lastName}
        //helperText="Last Name"
        onChange={(event) => setLastName(event.target.value)}
      />

      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Address Line 1"
        required={true}
        type="text"
        value={billingAddress}
        //helperText="Address Line 1"
        onChange={(event) => setBillingAddress(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Address Line 2"
        required={false}
        type="text"
        value={billingAddress2}
        //helperText="Address Line 2"
        onChange={(event) => setBillingAddress2(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="City"
        required={true}
        type="text"
        value={billingCity}
        //helperText="City"
        onChange={(event) => setBillingCity(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="State"
        required={true}
        type="text"
        value={billingState}
        //helperText="State"
        onChange={(event) => setBillingState(event.target.value)}
      />

      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Billing Zip"
        required={true}
        type="number"
        value={billingPostalCode}
        //helperText="Zip Code"
        onChange={(event) => setBillingPostalCode(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={true}
        label="Country"
        required={true}
        type="text"
        value={"United States"}
        //helperText="Country"
        onChange={(event) => setBillingCountryRegion("United States")}
      />

      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Phone"
        required={false}
        type="phone"
        value={billingPhoneNumber}
        //helperText="Phone"
        onChange={(event) => setBillingPhoneNumber(event.target.value)}
      />

      <div className="mt-3 button-centered">
        <LoadingButton
          disabled={!acceptTerms}
          variant="contained"
          color="primary"
          type="submit"
          loading={loading}
          onClick={handleSubmit}
          className="button mt-1 red-button red-pill-button"
        >
          Submit Payment for {getPriceString()}
        </LoadingButton>
      </div>
    </FormControl>
  );

  const inputTermsPrivacy = (
    <div className="mb-3 form-check mx-auto d-flex justify-content-center">
      <input
        type="checkbox"
        className="form-check-input me-3"
        checked={acceptTerms}
        onChange={(e) => setAcceptTerms(!acceptTerms)}
        id="terms"
      />
      <label className="form-check-label" htmlFor="terms">
        I agree to the{" "}
        <a
          rel="noopener"
          className="text-decoration-underline title"
          href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
          target="_new"
        >
          {" "}
          terms &amp; conditions
        </a>
      </label>
    </div>
  );
  const modalFooter = (
    <div className="bg-banner my-3 px-4 text-center ">
      <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
        <p className="mb-0 me-3 text-white">Want more size options?</p>
        <button
          onClick={() => {
            navigate("/pricing");
          }}
          className="button"
        >
          View Plans
        </button>
      </div>
    </div>
  );

  const authorizeNetSection = (
    <div id="an-toplevel">
      <div className="AuthorizeNetSeal">
        <script type="text/javascript" language="javascript">
          var ANS_customer_id="1b923780-b62e-42c7-a551-eef1f3faeedf";
        </script>
        <script
          type="text/javascript"
          language="javascript"
          src="//verify.authorize.net:443/anetseal/seal.js"
        ></script>
      </div>
      <div>
        <a href="https://usa.visa.com/legal/privacy-policy.html">
          Authorize.Net Privacy Policy
        </a>
      </div>
    </div>
  );

  const ImageCardTags = data ? (
    <div>
      <Typography variant="body2" color="text.secondary">
        Topics
      </Typography>
      <div>
        {data.tags
          ? data.tags
              .filter((tag) => {
                return tag.split(":")[0] === "topic";
              })
              .map((tag) => {
                return (
                  <Chip
                    sx={{ margin: 0.2 }}
                    label={
                      <Typography
                        sx={{ margin: 1, fontFamily: "Raleway", fontSize: 12 }}
                      >
                        {tag.split(":")[1]}
                      </Typography>
                    }
                  />
                );
              })
          : null}
      </div>
    </div>
  ) : (
    <div></div>
  );

  const ImageCardDetails = data ? (
    <Card className="img-wrapper" sx={{}}>
      <CardHeader
        action={<IconButton aria-label="settings"></IconButton>}
        title={
          <div>
            <Typography variant="h6" color="text.primary">
              {packImages.length} total images
            </Typography>
            <br />

            {data.tags
              ? data.tags
                  .slice(0, 10)
                  .filter((tag) => {
                    return (
                      tag.split(":")[0] !== "topic" ||
                      tag.split(":")[0] !== "category"
                    );
                  })
                  .map((tag) => {
                    return (
                      <Chip
                        sx={{ margin: 0.2 }}
                        label={
                          <Typography
                            sx={{
                              margin: 1,
                              fontFamily: "Raleway",
                              fontSize: 12,
                            }}
                          >
                            {tag.split(":")[1]}
                          </Typography>
                        }
                      />
                    );
                  })
              : null}
          </div>
        }
      ></CardHeader>
      <CardContent>
        <div className="container mb-5">
          {packImages && packImages.length > 0 ? (
            <Tiles data={packImages}></Tiles>
          ) : (
            <div className="row">
              <div className="col text-center">Loading pack images...</div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  ) : (
    <div></div>
  );

  const ImagePurchaseSection = data ? (
    <div className="d-flex flex-column h-100 justify-content-evenly mb-3">
      <div className="d-flex flex-fill align-items-center">
        <div className="text-center mx-auto">
          <i className="fa-solid fa-coins"></i>
          <span className="ms-2">
            Monthly Credits Balance:{" "}
            {subscription.numSubscriptionCreditsRemaining}
          </span>
          <br />
          <span className="ms-2">
            Wallet Credits: {subscription.numWalletCreditsRemaining}
          </span>
          <br />
          <Button
            variant="contained"
            disabled={!auth.user}
            className="button mt-1"
            onClick={() => {
              if (auth.user) {
                setModalOpen(true);
                // console.log("clicked modal");
              } else {
                // console.log("sent to subscription screen");
                // TODO:: navigate to subscription screen
                //navigate('/signup');
                setModalOpen(true);
              }
            }}
          >
            {getDownloadButtonText()}
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );

  const ImageCard = data ? (
    <Card className="" sx={{}}>
      <CardContent>
        <CardHeader
          title={
            <div>
              <Chip
                variant="contained"
                color="secondary"
                label={<Typography>{" " + data.category + " "}</Typography>}
              />
              &nbsp;
              <Chip
                variant="contained"
                color="secondary"
                label={
                  <Typography>{" " + packImages.length + " images"}</Typography>
                }
              />
              <Typography variant="h6" color="text.primary">
                {data ? data.name + " - " + data.title : "No Pack"}
              </Typography>
            </div>
          }
          action={<IconButton aria-label="settings"></IconButton>}
        />
        <CardMedia
          component="img"
          height="300"
          image={data.cover_image_url}
          alt={data.description}
        />

        {ImagePurchaseSection}

        <Typography variant="body2" color="text.secondary">
          <div className="fw-bold mb-1">Title</div>
          <div className="my-2">{data.title}</div>
          <div className="fw-bold mb-1">Description</div>
          <div className="my-2">{data.description}</div>
          <div>
            <span className="fw-bold mb-1">created by:</span>
            <span className="mb-1"> {data.created_by}</span>
          </div>
          <div>
            <span className="fw-bold mb-1">published </span>
            <span className="mb-1">
              <TimeAgo date={data.generated_timestamp} />
            </span>
          </div>
        </Typography>
        <br />
        <div className="text-align-right">{ImageCardTags}</div>
      </CardContent>
    </Card>
  ) : (
    <div></div>
  );

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {data ? (
        <div className="mb-3">
          {modalOpen && (
            <Modal
              setOpenModal={setModalOpen}
              title="Confirm Content Pack Purchase"
              portalClassName="modal-container"
              headerClassName="modal-header"
              bodyClassName="modal-body"
            >
              <div className="align-items-center">
                <div className="img-wrapper ">
                  <img
                    className="img-fluid img-thumbnail"
                    src={data.cover_image_url}
                    alt={data.title}
                  />

                  <div className="">
                    <p />
                    <ul>
                      <li className="ms-1">
                        Price: {getPriceString() + " for " + getNumImages()}
                      </li>

                      <li className="ms-1">
                        Title: {getSafeTitle(data.title)}
                      </li>
                      <li className="ms-1">Creator: {data.created_by}</li>
                    </ul>
                  </div>
                  <div className="mt-1">
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {inputTermsPrivacy}
                    </Box>
                  </div>

                  <div id="pm_section">
                    <div id="or">PayPal</div>
                    <div className="">{PayPalOrderSection}</div>

                    <div id="or">or</div>
                    <div className="cc-form align-items-center justify-content-center">
                      <div className="d-flex bg-white flex-column align-items-center justify-content-center">
                        <img src={AuthorizeNetLogo} className="mt-3" />
                        <br />
                        {authorizeNetSection}
                      </div>

                      <div className="">{creditCardForm}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}

          <div className="container">
            <div className="row px-5 gx-5 gy-2">
              {ImageCard}

              {ImageCardDetails}
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col text-center">Loading Details...</div>
        </div>
      )}
    </>
  );
}
