import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../services/auth/use-auth';
import UserService from '../../services/user.service';

const Payment = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedPlan } = location.state;
    
    const handleSignUp = async () => {
      try {
        var upgradeResponse = await UserService.UpgradeUserAccount({userId: auth.user.uid, plan: selectedPlan})
        // console.log('upgradeResponse: ', upgradeResponse);
        // console.log('payment successfully processed...')
        navigate('/')
      } catch(error) {
        // console.log('error processing payment to upgrade user account: ', error);
        // Prompt user of Payment Error
      }
    };

    return (
        <div>
          <h1>Payment</h1>
          <h2>{selectedPlan}</h2>
          <button onClick={() => handleSignUp()}>
              Purchase
          </button>

          <div className="AuthorizeNetSeal"><script type="text/javascript" language="javascript">var ANS_customer_id="1b923780-b62e-42c7-a551-eef1f3faeedf";</script>
            <script type="text/javascript" language="javascript" src="//verify.authorize.net:443/anetseal/seal.js" ></script> 
          </div>
          <div><a href='https://usa.visa.com/legal/privacy-policy.html'>Authorize.Net Privacy Policy</a></div>
        </div>
    );
}

export default Payment;