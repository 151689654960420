import { Chip, Typography } from "@mui/material";
import  CopyrightLogo  from "../../assets/img/copyright-256x256.jpg";
import  BugsLogo  from "../../assets/img/bug-report-256x256.png";
import  FeedbackLogo  from "../../assets/img/smartai-studio-feedback.jpg";
import logo from '../../assets/img/logo.png';
import platform from '../../assets/img/logo2_tiny.png';
import { getThemez } from '@mui/material';

import {
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions,
  Avatar,
  IconButton,
} from "@mui/material";

import "./About.css";
import { EmailIcon } from "react-share";

export default function About() {


  const emailBug = (
    <div>
      <Typography variant="body2" component="div">
        Email Us <a href="mailto:bugs@smartai.studio">bugs@smartai.studio</a>
      </Typography>
    </div>
  )
  const emailCopyright = (
    <div>
      <Typography variant="body2" component="div">
        Email Us <a href="mailto:copyright@smartai.studio">copyright@smartai.studio</a>
      </Typography>
    </div>
  )
  const emailFeedback = (
    <div>
      <Typography variant="body2" component="div">
        Email Us <a href="mailto:feedback@smartai.studio">feedback@smartai.studio</a>
      </Typography>
    </div>
  )
  const emailSaSSY = (
    <div>
      <Typography variant="body2" component="div">
        Email Us <a href="mailto:info@smartai.systems">info@smartai.systems</a>
      </Typography>
    </div>
  )
  const CopyrightBox = (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 300,
                height: 480,
              },
            }}
          >
          <Card className="img-wrapper" sx={{}}>
            <CardContent>
              <CardHeader
                avatar={ 
                  <Box>
                    <Avatar  
                      sx={{ bgcolor: 'black' }}
                    >                  
                      C
                    </Avatar>
                    <Avatar >                  
                    <EmailIcon  />
                  </Avatar>
                 </Box>
                } 
                title="Copryright Inquiry?"     
                subheader={emailCopyright}      
              />
              <CardMedia
                component="img"
                height="256" width="256"
                image={CopyrightLogo}
              />
              <Typography 
                sx={{ align: 'left'}}
                variant="body2" 
                component="div"
              >
                Please include the following information:
                <ul className="left">
                  <li>claim - which image (url or id)</li>                  
                  <li>proof - copyrighted image (url)</li>
                </ul>

              </Typography>
            </CardContent>
          </Card>
          </Box>
  )
  const BugsBox = (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        "& > :not(style)": {
          m: 1,
          width: 300,
          height: 480,
        },
      }}
    >
    <Card className="img-wrapper" sx={{}}>
      <CardContent>
        <CardHeader
          avatar={ 
            <Box>
                    <Avatar 
                     sx={{ bgcolor: 'red' }}
                     >                  
                      B
                    </Avatar>
                    <Avatar >                  
                    <EmailIcon  />
                  </Avatar>
                 </Box>
          } 
          title="Report Bug?"     
          subheader={emailBug}      
        />
        <CardMedia
          component="img"
          height="256" width="256"
          image={BugsLogo}
        />
         <Typography 
                sx={{ align: 'left'}}
                variant="body2" 
                component="div"
          >
             Please include the following information:
            <ul className="left">
              <li>what happened?</li>
              <li>what were you doing?</li>
              <li>what browser? what device?</li>
            </ul>
           Want a new category?  specific type of image?  new feature?  let us know!
                
          </Typography>
      </CardContent>
    </Card>
    </Box>
)
  const FeedbackBox = (
        <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: 300,
                height: 480,
              },
            }}
          >
          <Card className="img-wrapper" sx={{}}>
            <CardContent>
              <CardHeader
                avatar={ 
                  <Box>
                  <Avatar 
                    sx={{ bgcolor: '#183e4b' }}
                   >                  
                    F
                  </Avatar>
                  <Avatar >                  
                  <EmailIcon  />
                </Avatar>
               </Box>
                } 
                title="Feedback Inquiry?"     
                subheader={emailFeedback}      
              />
              <CardMedia
                component="img"
                height="256" width="256"
                image={FeedbackLogo}
              />
               <Typography 
                sx={{ align: 'left'}}
                variant="body2" 
                component="div"
          >
             Send us your feedback:
                <ul className="left">
                  <li>new feature request?</li>
                  <li>new category of images?</li>
                  <li>you hate us? you love us?</li>
                </ul>
         
              </Typography>
            </CardContent>
          </Card>
          </Box>
  )

  const SaSSYBox = (
    <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: 300,
            height: 480,
          },
        }}
      >
      <Card className="img-wrapper" sx={{}}>
        <CardContent>
          <CardHeader
            avatar={ 
              <Box>
              <Avatar 
                sx={{ bgcolor: '#183e4b' }}
               >                  
                S
              </Avatar>
              <Avatar >                  
              <EmailIcon  />
            </Avatar>
           </Box>
            } 
            title="SaSSY Inquiry?"     
            subheader={emailSaSSY}      
          />
          <CardMedia
            className="img-thumbnail"
            component="img"
            height="256" width="256"
            image={platform}
          />
           <Typography 
            sx={{ align: 'left'}}
            variant="body2" 
            component="div"
        >
         Considering using SaSSY to drive your Generative AI projects?  
           
          </Typography>
          <Typography 
            sx={{ align: 'left'}}
            variant="body2" 
            component="div"
        >
         Send us an inquiry!
           
          </Typography>
        </CardContent>
      </Card>
      </Box>
)
  return (
    <div>
      <div className="bg-hero px-4 py-5 text-center">
        <h1 className="display-5 fw-bold">
          About SmartAI Stock Images
        </h1>
        <div className="col-lg-12 ">
          <div className="row">
            <p className="lead mb-2">
              SmartAI Stock Images is a <span className="bold">smartai.studio</span> production, 
              a solution for creating, downloading and sharing AI generated stock images. 
              </p>
              <p className="lead mb-2">
              All images are original SmartAI generated and prompted images. Feel free to contact us with any questions or comments.
            </p>
            <div className="col">{CopyrightBox}</div>
            <div className="col">{FeedbackBox}</div>
            <div className="col">{BugsBox}</div>
          </div>
          <div className="row">

          </div>
          <div className="mt-5 bg-banner-info px-4 text-center ">
          <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
            <p className="mb-0 me-3">SmartAI Generative Platform</p>
          </div>
        </div>
         </div>
            <p className="mt-2 lead mb-2">
              SmartAI Stock Images is powered by the SmartAI Generative Platform, affectionately named SaSSY.       </p>
              <p className="lead mb-4"> SaSSY is currently in BETA development, and we are always looking for feedback.</p>  
            {SaSSYBox}
        </div>

    </div>
  );
}
