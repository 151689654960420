import { apiURI } from "../shared/api.settings";

class ImageService {
    static GetImageByIdentifier = async (identifier) => {
        return fetch(`${apiURI}/image/${identifier}`)
          .then((response) => response.json());
    }

    static GetCleanImages = async (page = 0, pageSize = 48) => {
      var images = this.GetImages(page, pageSize);
      // loop through images and replace space in url with %20
      for (var i = 0; i < images.length; i++) {
        images[i].public_url_thumbnail = images[i].public_url_thumbnail.replace(/ /g, '%20');
        images[i].public_url_protected = images[i].public_url_protected.replace(/ /g, '%20');
        images[i].public_url_reduced = images[i].public_url_reduced.replace(/ /g, '%20');
      }
      return images;
    }


    static GetImages = async (page = 0, pageSize = 48) => {
        return fetch(`${apiURI}/image/?page=${page}&pageSize=${pageSize}`)
          .then((response) => response.json());
    }

    static GetUserImages = async (userId, page = 0, pageSize = 48, token) => {
      return fetch(`${apiURI}/image/user?userId=${userId}&page=${page}&pageSize=${pageSize}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then((response) => response.json());
    }
   
    static GetImagesBySearchTerm = async (searchTerm = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/image/search/${searchTerm}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    static GetImagesByTag = async (tag = 'tag', tagValue = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/image/tag/{tag}/{tagValue}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    static GetImagesByTopic = async (topic = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/image/topic/${topic}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    static GetImagesByMaterial = async (material = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/image/materials/${material}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    static GetImagesByCategory = async (category = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/image/category/${category}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    static GetImagesByColor = async (color = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/image/color/${color}?page=${page}&pageSize=${pageSize}`)
        .then((response) => response.json());
    }

    /*
    static GetImagePrice = async (width, height) => {
      return fetch(`${apiURI}/price?width=${width}&height=${height}`)
        .then((response) => response.json());
    }
  
    static GetImagePricesheet = async (color = '',page = 0, pageSize = 48) => {
      return fetch(`${apiURI}/prices`)
        .then((response) => response.json());
    }
    */
}

export default ImageService;