import React, { useState, useEffect } from "react";
//import MultiDropdown from "../../components/multi-dropdown/MultiDropdown";
import PackTiles from "../../components/PackTiles/PackTiles";
//import Tiles from "../../components/Tiles/Tiles";
//import ImageService from "../../services/image.service";
import ContentPackService from "../../services/contentpack.service";
import ListService from "../../services/list.service";
//import { Link } from "react-router-dom";
import Searchbar from "../../components/searchbar/searchbar";

import "./Packs.css";
import { Backdrop, CircularProgress, Paper } from "@mui/material";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions,
  Avatar,
  IconButton,
  Box,
  Typography,
  Link,
  Chip,
} from "@mui/material";
import { createSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TimeAgo from "react-timeago";

export default function Packs() {
  //const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [featured, setFeatured] = useState(null);
  const [loading, setLoading] = useState(true);
  
  //const [page, setPage] = useState(0);
  const [categories, setCategories] = useState(null);
  const navigate = useNavigate();

  const _goToTileDetailsPage = () => {
    if (featured) {
      navigate({
        pathname: "/pack",
        search: createSearchParams({
          id: featured.content_pack_id,
        }).toString(),
      });
    }
  };
  const featureClickHandler = (e) => {
    e.preventDefault();
    _goToTileDetailsPage();
  };



  const loadMore = async (startIndex, stopIndex, currentItems) => {
    /*
    setPage((prevPage) => prevPage + 1);
    const response = await ContentPackService.GetContentPacks(page);
    setData((prevData) => [...prevData, ...response]);
    // console.log("fetching more content packs Data");
    // console.log("data", data);
    */
  };

  useEffect(() => {
    const fetchListData = async () => {
      const response = await ListService.GetCategories();
      setCategories(response);
    };

    const pageData = async () => {
      const response = await ContentPackService.GetContentPacks(0);
      setData([...response]);
      // console.log("fetching content packs Data, page: ", 0, "data", response);
      setFeatured(response[0] ? response[0] : null);
      // console.log("featured content pack", featured);
      setLoading(false);
    };

    pageData();
    fetchListData();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="px-4 py-5 text-center">
        <Searchbar/>
        <h1 className="display-5 fw-bold mt-4">
          Content Packs
        </h1>
        
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-4">
              SmartAI curated topic-based collections of images.
            </p>
            <p> 
              Coming Soon, SmartAI collaborations with artists & influencers showcasing their amazing work. 
            </p>
          </div>
        
         
      </div>

      <Box
          sx={{ textJustify: "auto",  p: 2 }}
          className="px-4 text-center "
        >
           <div className="bg-banner-info px-4 text-center ">
            <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
              Featured Pack
            </div>
          </div>
          <Card 
            sx={{ maxWidth: 550, textAlign: "center", backgroundColor: "#DDDDDD", p: 1  }}
            className="featured-pack mx-auto" 
            onClick={featureClickHandler}
          >
            <CardHeader
              avatar={
                <div>
                  <Chip color="secondary"  label={
                  <Typography>
                    {featured ? featured.category : ""}
                  </Typography>} /> 
                  &nbsp;                
                  <Chip color="primary" 
                    className="justify-right"
                    label={
                    <Typography>
                      {featured ? "$" + featured.price : ""}
                    </Typography>} />  
                            
                </div>           
              }
  
            />

            <CardMedia
              component="img"
              className="img-responsive img-thumbnail"
              sx={{ height: 250}}
              src={featured ? featured.cover_image_url : null}
              alt={featured ? featured.title : 'No Featured Pack'}
              //onClick={featured ? _goToTileDetailsPage() : null}
            />
            <CardContent>
              <Typography variant="body3">{featured ? (featured.name + " - " + featured.title) : 'No Featured Pack'}</Typography>
              <Typography variant="body2">{featured ? ("created by: " + featured.created_by) : ''}</Typography>
              <Typography variant="body2"><TimeAgo date={featured ? featured.generated_timestamp: null} /></Typography>            
            </CardContent>
          </Card>
        </Box>
      
      <div className="bg-banner-info px-4 text-center ">
          <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
            <p className="mb-0 me-3">Other Content Packs</p>
          </div>
        </div>
      <div>
        <div className="container mb-5">
          {data.length > 0 ? (
            <PackTiles data={data} loadMore={loadMore}>
            </PackTiles>
          ) : (
            <div className="row">
              <div className="col text-center">Loading content packs...</div>
            </div>
          )}
        </div>
        
      </div>
    </div>
  );
}
