import React, { useState, useEffect } from "react";
//import { makeStyles } from '@material-ui/core/styles';
import Alert  from '@mui/joy/Alert';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import TextField from '@mui/joy/TextField';
import Link from '@mui/joy/Link';
import { useNavigate } from 'react-router-dom';
import { IMaskInput, IMask } from 'react-imask';

import ProductService from '../../services/product.service';
import { useAuth } from '../../services/auth/use-auth';
import "./CreditCardForm.css";

const CreditCardExpiration = React.forwardRef(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="MM / YY"
        definitions={{
          'MM': {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12
          },
          'YY': {
            mask: IMask.MaskedRange,
            from: 23,
            to: 99
          }
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

export default function CreditCardForm() {
  //const classes = useStyles();
  const [amount, setAmount] = useState();
  const [cardNumber, setCardNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');  
  const [billingAddress, setBillingAddress] = useState('');
  const [billingAddress2, setBillingAddress2] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingCountryRegion, setBillingCountryRegion] = useState('');
  const [billingPostalCode, setBillingPostalCode] = useState('');
  const [billingPhoneNumber, setBillingPhoneNumber] = useState('');
  const [billingEmail, setBillingEmail] = useState('');  
  const [showError, setShowError] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (!cardNumber || !expirationDate || !cvv || !billingAddress || !billingPostalCode) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  }, [cardNumber, expirationDate, cvv, billingAddress, billingPostalCode]);

  const handleSubmit = (event) => {
    //event.preventDefault();
    // console.log("Handle Submit - Credit Card Form")
    if (!cardNumber || !expirationDate || !cvv || !billingAddress || !billingPostalCode) {
      setShowError(true);
      return;
    }

    setShowError(false);
    // Add code to process the payment here
    var response = ProductService.CapturePayment(
      auth.user.uid,
      auth.user.email,
      {
        product: {
          name: "Test Product",
          description: "Test Product Description",
          price: 100,
          currency: "USD"
        },
        creditCard: {
          amount,
          cardNumber,
          expirationDate,
          cvv,
          firstName,
          lastName,
          billingAddress,
          billingAddress2,
          billingCity,
          billingState,
          billingCountryRegion,
          billingPostalCode,
          billingPhoneNumber,
          billingEmail      
          }
      }
    );
  };

  return (
    <div className="form-cc">
      {showError && (
        <Alert severity="error">Please fill out all fields</Alert>
      )}
      <form className="" onSubmit={handleSubmit}>
        <Input
          name="Card Number"
          required={true}
          type="number"
          value={cardNumber}
          placeholder="enter card number"
          onChange={(event) => setCardNumber(event.target.value)}
        />
        <Input
          name="Expiration Date"
          required={true}
          type="text"
          value={expirationDate}
          inputComponent={CreditCardExpiration}
          placeholder="MM/YY"
          onChange={(event) => setExpirationDate(event.target.value)}
        />
        <Input
          name="CVV"
          required={true}
          type="number"
          value={cvv}
          placeholder="CVV"
          onChange={(event) => setCvv(event.target.value)}
        />
        <Input
          name="First Name"
          required={true}
          type="text"
          value={firstName}
          placeholder="First Name"
          onChange={(event) => setFirstName(event.target.value)}
        />
        <Input
          name="Last Name"
          required={true}
          type="text"
          value={lastName}
          placeholder="Last Name"
          onChange={(event) => setLastName(event.target.value)}
        />


        <Input
          name="Address Line 1"
          required={true}
          type="text"
          value={billingAddress}
          placeholder="Address Line 1"
          onChange={(event) => setBillingAddress(event.target.value)}
        />
        <Input 
          name="Address Line 2"
          required={false}
          type="text"
          value={billingAddress2}
          placeholder="Address Line 2"
          onChange={(event) => setBillingAddress2(event.target.value)}
        />
        <Input
          name="City"
          required={true}
          type="text"
          value={billingCity}
          placeholder="City"
          onChange={(event) => setBillingCity(event.target.value)}
        />
        <Input
          name="State"
          required={true}
          type="text"
          value={billingState}
          placeholder="State"
          onChange={(event) => setBillingState(event.target.value)}
        />
        
        <Input
          name="Billing Zip"
          required={true}
          type="number"
          value={billingPostalCode}
          placeholder="Zip Code"
          onChange={(event) => setBillingPostalCode(event.target.value)}
        />
        <Input
          name="Country"
          required={true}
          type="text"
          value={billingCountryRegion}
          placeholder="Country"
          onChange={(event) => setBillingCountryRegion(event.target.value)}
        />
        <Input 
          name="Email"
          required={false}
          type="email"
          value={billingEmail}
          placeholder="Email"
          onChange={(event) => setBillingEmail(event.target.value)}
        />
        <Input
          name="Phone"
          required={false}
          type="phone"
          value={billingPhoneNumber}
          placeholder="Phone"
          onChange={(event) => setBillingPhoneNumber(event.target.value)}
        />
       
        <Input
          name="Amount"
          required={true}
          type="Money"
          value={amount}
          placeholder="$0.00"
          onChange={(event) => setAmount(event.target.value)}
        />
      
        <button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          className="red-button red-pill-button"
        >
          Submit Payment
        </button>
      </form>

      <div className="AuthorizeNetSeal"><script type="text/javascript" language="javascript">var ANS_customer_id="1b923780-b62e-42c7-a551-eef1f3faeedf";</script>
            <script type="text/javascript" language="javascript" src="//verify.authorize.net:443/anetseal/seal.js" ></script> 
          </div>
          <div><a href='https://usa.visa.com/legal/privacy-policy.html'>Authorize.Net Privacy Policy</a></div>

    </div>
    
  );
} 