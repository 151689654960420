import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Divider,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { FormControl, TextField } from "@mui/material";
import InputMask from "react-input-mask";
import { useSearchParams, useNavigate } from "react-router-dom";
import ProductService from "../../services/product.service";
import UserService from "../../services/user.service";
import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Modal from "../../components/modal/modal";
import { useAuth } from "../../services/auth/use-auth";
import AuthorizeNetLogo from "../../assets/img/authorize-net-visa-logo.jpg";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IMask, IMaskInput } from "react-imask";

import "./pricing.css";
import { LoadingButton } from "@mui/lab";

export default function Pricing() {
  const [selectedPlan, setSelectedPlan] = React.useState("Free");
  const auth = useAuth();
  //const [{ isPending }] = usePayPalScriptReducer();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  //const [categories, setCategories] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedPack, setSelectedPack] = useState(0);
  const [cardNumber, setCardNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [billingCountryRegion, setBillingCountryRegion] =
    useState("United States");
  const [billingState, setBillingState] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingPhoneNumber, setBillingPhoneNumber] = useState("");
  const [billingEmail, setBillingEmail] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchData = async () => {
      getCreditPacks();
    };

    const getSubscription = async () => {
      if (auth.user) {
        const response = await UserService.GetUserCredits(auth.user.uid);
        setSubscription(response.creditProfile);

        /*
        // // console.log(
          "Logged in, subscription info for auth.user.id:",
          auth.user.uid,
          "\n",
          response
        );
        */

        //// // console.log("CreditProfile: ", JSON.stringify(response.creditProfile));
      } else {
        //const response = await UserService.GetUserCredits("User123");
        var credit = {
          creditProfile: {
            numSubscriptionCreditsRemaining: -1,
            numWalletCreditsRemaining: -1,
            numPendingCredits: -1,
          },
        };
        setSubscription(credit);
        //// // console.log("No Subscription - Not Logged in ", JSON.stringify(credit));
      }
    };

    // {data.title.substring(0, 100) + "..."}
    fetchData();
    getSubscription();
    //getPrices();
  }, [auth.user]);

  const getCreditPacks = async () => {
    // get products
    const productResponse = await ProductService.GetCreditPacks();
    //// // console.log("CreditPacks:", productResponse);
    setProducts(productResponse);
  };

  const getCreditPackNumCredits = (index) => {
    //// // console.log("getCreditPackNumCredits(): index: " + index);
    if (!products) getCreditPacks();
    if (products && products.length > 0) {
      var pack = products[index];
      //// // console.log("getCreditPackNumCredits(): " + JSON.stringify(pack));

      if (pack) {
        var credits = pack.creditPackQuantity;
        return credits;
      }
    }

    return "0";
  };

  const getCreditPackPrice = (index) => {
    //// // console.log("getCreditPackPrice(): index: " + index);
    if (!products) getCreditPacks();
    if (products && products.length > 0) {
      var packPrices = [4.99, 9.99, 19.99, 24.99];
      //// // console.log("getCreditPackPrice(): " + JSON.stringify(packPrices[index]));

      return packPrices[index];
    }

    return "0.0";
  };

  const getPriceString = (index) => {
    var price = getCreditPackPrice(index);

    if (price) {
      var priceFormatted = price.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
      return priceFormatted + " USD";
    } else {
      return "0.00 USD";
    }
  };

  const purchasePackPP = async (transactionId) => {
    if (!products) getCreditPacks();

    var packSelected = products[selectedPack];
    //// // console.log(
    //  "purchasePackPP(): Selected Pack" + JSON.stringify(packSelected)
    // );

    if (packSelected) {
      var price = getCreditPackPrice(selectedPack);

      // // // console.log(
      //  "purchasePackPP(): " + price + " transaction Id: " + transactionId
      //);
      //if (auth.user && acceptTerms && auth.user.id && identifier !== "") {
      if (auth.user) {
        /*
        // // console.log(
          "purchasePackPP() - Passed  check: id #",
          packSelected.creditPackId,
          ", acceptTerms " + acceptTerms,
          " user: ",
          JSON.stringify(auth.user.id)
        );
        */

        var userId = auth.user.uid;

        const response = await ProductService.PurchaseCreditPackPP(
          userId,
          packSelected.creditPackId,
          price,
          transactionId
        );

        if (response.success === false) {
          alert("Purchase failed: " + response.message);
          // // console.log("purchasePack: " + response);
        } else {
          alert(
            "Successful! - moving ",
            `${packSelected.creditPackQuantity}`,
            " credits to your account."
          );
          navigate("/profile");
        } // purchase repsonse check
      } // product purchase check
      else alert("Please login or register to purchase credits");
    } // product check
    else
      alert(
        "Pack not found - contact support to get your credits added to your account"
      );
    setLoading(false);
  };

  const checkLuhn = (cardnumber) => {
    // Build an array with the digits in the card number
    var digits = cardnumber.split("");
    for (var i = 0; i < digits.length; i++) {
      digits[i] = parseInt(digits[i], 10);
    }
    // Run the Luhn algorithm on the array
    var sum = 0;
    var alt = false;
    for (i = digits.length - 1; i >= 0; i--) {
      if (alt) {
        digits[i] *= 2;
        if (digits[i] > 9) {
          digits[i] -= 9;
        }
      }
      sum += digits[i];
      alt = !alt;
    }
    // Check the result
    if (sum % 10 === 0) {
      //// // console.log("Luhn check passed");
      return true;
    } else {
      //// // console.log("Luhn check failed");
      return false;
    }
  };
  const validateCC = () => {
    //// // console.log("validateCC - checking");
    let error = "";

    if (cardNumber && cardNumber.length === 16) {
      if (
        expirationDate &&
        (expirationDate.length === 4 || expirationDate.length === 5)
      ) {
        if (cvv && (cvv.length === 3 || cvv.length === 4)) {
          if (billingCountryRegion) {
            if (billingState) {
              if (billingCity) {
                if (billingAddress) {
                  if (billingPostalCode) {
                    if (firstName) {
                      if (lastName) {
                        var card = {
                          creditCardNumber: cardNumber,
                          expirationDateString: expirationDate,
                          cvv: cvv,
                          countryRegion: billingCountryRegion,
                          state: billingState,
                          city: billingCity,
                          addressLine1: billingAddress,
                          addressLine2: billingAddress2,
                          postalCode: billingPostalCode,
                          phoneNumber: billingPhoneNumber,
                          email: billingEmail,
                          firstName: firstName,
                          lastName: lastName,
                        };

                        if (checkLuhn(card.creditCardNumber)) {
                          // // console.log("validateCC(): card number is valid");
                          return card;
                        } else {
                          error = "card number is invalid";
                          //return null;
                        }
                      } else error = "invalid last name";
                    } else error = "invalid first name";
                  } else error = "invalid postal code)";
                } else error = "invalid address";
              } else error = "invalid city";
            } else error = "invalid state";
          } else error = "invalid country";
        } else error = "invalid cvv";
      } else error = "invalid expiration date";
    } else error = "invalid card number";

    // // console.log("validateCC(): " + error);
    alert("Failed credit card validation: " + error);
    return null;
  };

  const purchasePackViaCC = async () => {
    if (!products) getCreditPacks();

    var packSelected = products[selectedPack];
    var price = getCreditPackPrice(selectedPack);
    //// // console.log(
    //  "purchasePackViaCC(): Selected Pack" + JSON.stringify(packSelected)
    //);

    if (auth.user && acceptTerms && packSelected && price) {
      /*
      // // console.log(
        "purchasePackViaCC() - Passed preconditions: id #",
        packSelected.content_pack_id,
        ", product id:",
        JSON.stringify(packSelected.creditPackId),
        ", product:",
        JSON.stringify(packSelected),
        " acceptTerms " + acceptTerms,
        " user: ",
        JSON.stringify(auth.user.uid)
      );
      */

      var userId = auth.user.uid;
      var email = auth.user.email;

      let creditCardInfo = validateCC();
      if (creditCardInfo) {
        if (auth.user && acceptTerms) {
          /*
              // // console.log(
                "purchasePackViaCC() - Passed  check: id #",
                packSelected.id,
                ", acceptTerms " + acceptTerms,
                " user: ",
                JSON.stringify(auth.user.uid)
              );
              */
          var userId = auth.user.uid;
          var email = auth.user.email;
          try {
            const response = await ProductService.PurchaseCreditPackCC(
              userId,
              email,
              packSelected.creditPackId,
              price,
              "USD",
              creditCardInfo
            );

            if (response.success === false) {
              alert("Purchase failed: " + response.message);
            } else {
              //// // console.log("downloadPack: " + response.product.pack_bundle_url);
              //// // console.log("downloadPack: " + response.product.assetUrl);
              //downloadBundle(response.product.pack_bundle_url);
              navigate("/profile");
            } // purchase repsonse check
          } catch (error) {
            // // console.log("purchasePack: " + error);
            alert("Purchase failed: " + error);
          }
        } // ccinfo check
        else {
          alert("Please fill out all required credit  cards fields");
        }
      } // credit card check
    } // user check
    else {
      alert("Pack not found - skipping purchase flow");
    }
    setLoading(false);
  };
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    //// // console.log("handleSubmit");
    var response = purchasePackViaCC();
    //// // console.log("handleSubmit: " + JSON.stringify(response));
  };

  const ProPlan = (
    <div className="col">
      <div className="card pricing-item mb-4 rounded-3 shadow-sm">
        <div className="card-header text-bg-secondary py-3"></div>
        <div className="card-body d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-4">
            Pro / Small B
          </h5>
          <h8 className="text-muted fw-light card-title pricing-card-title mb-4">
            (FULL RELEASE)
          </h8>
          <h2 className="card-title pricing-card-title fw-bold">$29.99</h2>
          <h4>
            <small className="text-muted fw-light">a month</small>
          </h4>
          <p className="fw-bold mt-2">1,000 Credits</p>
          <ul className="mt-3 mb-4 text-start">
            <li className="fw-bold">Includes 5 FREE content packs!</li>
            <li>Unlimited searches</li>
            <li>No watermarks</li>
            <li>Maximum donwload size 4096x4096</li>
            <li>Ability to AI Generate images</li>
            <li>Ability to AI Vary images</li>
          </ul>
          <Button
            disabled={true}
            variant="contained"
            className="mt-auto w-100 button-disabled"
            onClick={() => setSelectedPlan("Pro")}
          >
            NOT AVAILABLE
          </Button>
        </div>
      </div>
    </div>
  );

  const PremiumPlan = (
    <div className="col">
      <div className="card pricing-item mb-4 rounded-3 shadow-sm">
        <div className="card-header text-bg-secondary py-3"></div>
        <div className="card-body d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-4">
            Premium
          </h5>
          <h8 className="text-muted fw-light card-title pricing-card-title mb-4">
            (BETA RELEASE)
          </h8>
          <h2 className="card-title pricing-card-title fw-bold">$19.99</h2>
          <h4>
            <small className="text-muted fw-light">a month</small>
          </h4>
          <p className="fw-bold mt-2">600 Credits</p>
          <ul className="mt-3 mb-4 text-start">
            <li className="fw-bold">Includes 3 FREE content packs!</li>
            <li>Unlimited searches</li>
            <li>No watermarks</li>
            <li>Maximum donwload size 2048x2048</li>
            <li>Ability to AI Generate images</li>
            <li>Ability to AI Vary images</li>
          </ul>
          <Button
            disabled={true}
            variant="contained"
            className="mt-auto w-100 button-disabled"
            onClick={() => setSelectedPlan("Pro")}
          >
            NOT AVAILABLE
          </Button>
        </div>
      </div>
    </div>
  );

  const StandardPlan = (
    <div className="col">
      <div className="card pricing-item mb-4 rounded-3 shadow-sm">
        <div className="card-header text-bg-secondary py-3"></div>
        <div className="card-body d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-4">
            Standard
          </h5>
          <h8 className="text-muted fw-light card-title pricing-card-title mb-4">
            (BETA RELEASE)
          </h8>
          <h2 className="card-title pricing-card-title fw-bold">$9.99</h2>
          <h4>
            <small className="text-muted fw-light">a month</small>
          </h4>
          <p className="fw-bold mt-2">250 Credits</p>
          <ul className="mt-3 mb-4 text-start">
            <li className="fw-bold">Includes 1 FREE content pack!</li>
            <li>Unlimited searches</li>
            <li>No watermarks</li>
            <li>Maximum download size 1280x1280</li>
          </ul>
          <Button
            disabled={true}
            variant="contained"
            className="mt-auto w-100 button-disabled"
            onClick={() => setSelectedPlan("Standard")}
          >
            NOT AVAILABLE
          </Button>
        </div>
      </div>
    </div>
  );

  const FreePlan = (
    <div className="col">
      <div className="card pricing-item mb-4 rounded-3 shadow-sm">
        <div className="card-header text-bg-primary py-3"></div>
        <div className="card-body d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-4">
            Current
          </h5>
        </div>
        <div className="card-body d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-4">
            Free (Current Plan)
          </h5>
          <h8 className="text-muted fw-light card-title pricing-card-title mb-4">
            (ALPHA RELEASE)
          </h8>
          <h2 className="card-title pricing-card-title fw-bold">$0</h2>
          <h4>
            <small className="text-muted fw-light">a month</small>
          </h4>
          <p className="fw-bold mt-2">50 Credits</p>
          <ul className="mt-3 mb-4 text-start">
            <li>No watermark on downloaded images </li>
            <li>Maximum donwload size 1024x1024</li>
          </ul>

          <Link to={!auth.user ? "/signup" : "/"}>
            <Button
              variant="contained"
              hidden={modalOpen ? true : false}
              className="mt-auto w-100 button"
            >
              Continue
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );

  const CreditPack1 = (
    <div className="col">
      <div className="card mb-4 rounded-3 shadow-sm">
        <div className="card-header text-bg-secondary py-3"></div>
        <div className="card-body mx-2 d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-2">
            Credit Pack
          </h5>
          <h5 className="fw-bold card-title pricing-card-title mb-2">
            {getCreditPackNumCredits(0) + " Credits"}
          </h5>
          <h2 className="card-title pricing-card-title fw-bold">
            {getPriceString(0)}
          </h2>
          <div className="button-centered">
            <Button
              variant="contained"
              className="mt-auto rounded-pill w-50"
              disabled={auth.user ? false : true}
              hidden={modalOpen ? true : false}
              onClick={() => {
                setSelectedPack(0);
                if (auth.user) {
                  setModalOpen(true);
                } else {
                  setModalOpen(true);
                }
              }}
            >
              BUY
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const CreditPack2 = (
    <div className="col">
      <div className="card mb-4 rounded-3 shadow-sm">
        <div className="card-header text-bg-secondary py-3"></div>
        <div className="card-body mx-2 d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-2">
            Credit Pack
          </h5>
          <h5 className="fw-bold card-title pricing-card-title mb-2">
            {getCreditPackNumCredits(1) + " Credits"}
          </h5>
          <h2 className="card-title pricing-card-title fw-bold">
            {getPriceString(1)}
          </h2>
          <div className="button-centered">
            <Button
              variant="contained"
              className="mt-auto rounded-pill w-50"
              disabled={auth.user ? false : true}
              hidden={modalOpen ? true : false}
              onClick={() => {
                setSelectedPack(1);
                if (auth.user) {
                  setModalOpen(true);
                } else {
                  setModalOpen(true);
                }
              }}
            >
              Buy
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const CreditPack3 = (
    <div className="col">
      <div className="card mb-4 rounded-3 shadow-sm">
        <div className="card-header text-bg-secondary py-3"></div>
        <div className="card-body mx-2 d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-2">
            Credit Pack
          </h5>
          <h5 className="fw-bold card-title pricing-card-title mb-2">
            {getCreditPackNumCredits(2) + " Credits"}
          </h5>
          <h2 className="card-title pricing-card-title fw-bold">
            {getPriceString(2)}
          </h2>
          <div className="button-centered">
            <Button
              variant="contained"
              className="mt-auto rounded-pill w-50"
              disabled={auth.user ? false : true}
              hidden={modalOpen ? true : false}
              onClick={() => {
                setSelectedPack(2);
                if (auth.user) {
                  setModalOpen(true);
                } else {
                  setModalOpen(true);
                }
              }}
            >
              Buy
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const CreditPack4 = (
    <div className="col">
      <div className="card rounded-3 shadow-sm">
        <div className="card-header text-bg-secondary py-3"></div>
        <div className="card-body mx-2 d-flex flex-column">
          <h5 className="fw-bold card-title pricing-card-title mb-2">
            Credit Pack
          </h5>
          <h5 className="fw-bold card-title pricing-card-title mb-2">
            {getCreditPackNumCredits(3) + " Credits"}
          </h5>
          <h2 className="card-title pricing-card-title fw-bold">
            {getPriceString(3)}
          </h2>
          <div className="button-centered">
            <Button
              variant="contained"
              className="mt-auto rounded-pill w-50"
              disabled={auth.user ? false : true}
              hidden={modalOpen ? true : false}
              onClick={() => {
                setSelectedPack(3);
                if (auth.user) {
                  setModalOpen(true);
                } else {
                  setModalOpen(true);
                }
              }}
            >
              Buy
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
  const getCreditDisplay = (index) => {
    switch (index) {
      case 0:
        return CreditPack1;
      case 1:
        return CreditPack2;
      case 2:
        return CreditPack3;
      case 3:
        return CreditPack4;
      default:
        return CreditPack1;
    }
  };

  const inputTermsPrivacy = (
    <div className="mb-3 form-check mx-auto d-flex justify-content-center">
      <input
        type="checkbox"
        className="form-check-input me-3"
        checked={acceptTerms}
        onChange={(e) => setAcceptTerms(!acceptTerms)}
        id="terms"
      />
      <label className="form-check-label" htmlFor="terms">
        I agree to the{" "}
        <a
          rel="noopener"
          className="text-decoration-underline title"
          href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
          target="_new"
        >
          {" "}
          terms &amp; conditions
        </a>
      </label>
    </div>
  );

  const authorizeNetSection = (
    <div id="an-toplevel">
      <div className="AuthorizeNetSeal">
        <script type="text/javascript" language="javascript">
          var ANS_customer_id="1b923780-b62e-42c7-a551-eef1f3faeedf";
        </script>
        <script
          type="text/javascript"
          language="javascript"
          src="//verify.authorize.net:443/anetseal/seal.js"
        ></script>
      </div>
      <div>
        <a href="https://usa.visa.com/legal/privacy-policy.html">
          Authorize.Net Privacy Policy
        </a>
      </div>
    </div>
  );

  const onPaypalCreateOrder = (paypalData, actions) => {
    if ('paymentSource' in paypalData) {
      if (paypalData.paymentSource !== 'card') {
        setLoading(true);
      }
    }
    // // console.log("onPaypalCreateOrder: " + JSON.stringify(paypalData));
    // // console.log("onPaypalCreateOrder: " + JSON.stringify(data));

    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: getCreditPackPrice(selectedPack),
          },
        },
      ],
    });
  };

  const onPaypalApproveOrder = (data, actions) => {
    setLoading(true);
    // // console.log("onPaypalApproveOrder: " + JSON.stringify(data));
    return actions.order.capture().then((details) => {
      //const name = details.payer.name.given_name;
      const transactionId = details.purchase_units[0].payments.captures[0].id;
      //// // console.log("Paypal Transaction Details: " + JSON.stringify(details));
      //// // console.log("Calling post paypal purchase workflow");
      purchasePackPP(transactionId);
    });
  };

  /* PAYPAL - PAYMENT */
  const PayPalOrderSection = (
    <PayPalScriptProvider
      options={{
        "client-id": "AYCBsFGtcEQDi1Ya3-Y_-VGVuBDeR8w6hwOGJgY0uh7_Td0V9SErINEmldT19ERsj88ayON_ChmkBSXx", // LIVE
        // "client-id": "AdeF5xY0p5wqpz3dirQW6GYTxAUo674falyk-oZXjTlSXEPzVs3DHC2IhtLA2vEqLgMay4wwNAasWwyZ", // sandbox
        components: "buttons",
      }}
    >
      <PayPalButtons
        style={{ layout: "vertical" }}
        disabled={!acceptTerms}
        createOrder={(data, actions) => onPaypalCreateOrder(data, actions)}
        onApprove={(data, actions) => onPaypalApproveOrder(data, actions)}
        onCancel={(data) => setLoading(false)}
        onError={(err) => {
          alert("There was an error talking with PayPal");
          setLoading(false);
        }}
      />
    </PayPalScriptProvider>
  );

  /* CREDIT CARDS - AUTHORIZE.NET */
  /*
  const creditCardForm = (

    <form onSubmit={handleSubmit}>
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="Card Number"
        typeof="number"
        required={true}
        type="number"
        value={cardNumber}
        placeholder="enter card number"
        onChange={(event) => setCardNumber(event.target.value)}
      />
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="Expiration Date"
        required={true}
        type="text"
        value={expirationDate}
        placeholder="Expiration (MMYY)"
        onChange={(event) => setExpirationDate(event.target.value)}
      />
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="CVV"
        required={true}
        type="number"
        value={cvv}
        placeholder="CVV"
        onChange={(event) => setCvv(event.target.value)}
      />
       <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="Email"
        required={false}
        type="email"
        value={billingEmail}
        placeholder="Email"
        onChange={(event) => setBillingEmail(event.target.value)}
      />
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="First Name"
        required={true}
        type="text"
        value={firstName}
        placeholder="First Name"
        onChange={(event) => setFirstName(event.target.value)}
      />
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="Last Name"
        required={true}
        type="text"
        value={lastName}
        placeholder="Last Name"
        onChange={(event) => setLastName(event.target.value)}
      />

      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="Address Line 1"
        required={true}
        type="text"
        value={billingAddress}
        placeholder="Address Line 1"
        onChange={(event) => setBillingAddress(event.target.value)}
      />
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="Address Line 2"
        required={false}
        type="text"
        value={billingAddress2}
        placeholder="Address Line 2"
        onChange={(event) => setBillingAddress2(event.target.value)}
      />
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="City"
        required={true}
        type="text"
        value={billingCity}
        placeholder="City"
        onChange={(event) => setBillingCity(event.target.value)}
      />
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="State"
        required={true}
        type="text"
        value={billingState}
        placeholder="State"
        onChange={(event) => setBillingState(event.target.value)}
      />

      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="Billing Zip"
        required={true}
        type="number"
        value={billingPostalCode}
        placeholder="Zip Code"
        onChange={(event) => setBillingPostalCode(event.target.value)}
      />
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="Country"
        required={true}
        type="text"
        value={billingCountryRegion}
        placeholder="Country"
        onChange={(event) => setBillingCountryRegion(event.target.value)}
      />
     
      <Input
        className="cc-input"
        disabled={!acceptTerms}
        name="Phone"
        required={false}
        type="phone"
        value={billingPhoneNumber}
        placeholder="Phone"
        onChange={(event) => setBillingPhoneNumber(event.target.value)}
      />

    <label
        name="Amount"
        required={true}
        type="Money"
        value={getCreditPackPrice(selectedPack)}
        placeholder="{getSelectedPack().price}"
        //onChange={(event) => setAmount(event.target.value)}
      />
      <br />
      <div className="mt-3 button-centered">
        <Button
          disabled={!acceptTerms}
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          className="button mt-1 red-button red-pill-button"
        >
          Submit Payment for {getPriceString()}
        </Button>
      </div>
    </form>
 
  );
*/

  const CreditCardExpiration = React.forwardRef(function TextMaskCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="MM/YY"
        lazy={true}
        autofix={true}
        overwrite={true}
        onComplete={(value) =>
          onChange({
            target: { name: props.name, value: value.replace("/", "") },
          })
        }
        blocks={{
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
          },
          YY: {
            mask: IMask.MaskedRange,
            from: 23,
            to: 99,
          },
        }}
        inputRef={ref}
      />
    );
  });

  const creditCardForm = (
    <FormControl className="" onSubmit={handleSubmit}>
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        inputProps={{ inputMode: "numeric", pattern: "[0-9]* " }}
        disabled={!acceptTerms}
        label="Card Number"
        required={true}
        value={cardNumber}
        //helperText="Enter credit card number"
        onChange={(event) => setCardNumber(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Expiration Date (MM/YY)"
        InputProps={{ inputComponent: CreditCardExpiration }}
        required={true}
        name="expirationDate"
        defaultValue="MM/YY"
        value={expirationDate}
        onChange={(event) => setExpirationDate(event.target.value)}
      ></TextField>
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="CVV (3 or 4 digit code on card)"
        required={true}
        inputProps={{ inputMode: "numeric", pattern: "/^[0-9]{3,4}$/" }}
        value={cvv}
        //helperText="CVV"
        onChange={(event) => setCvv(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Email"
        required={false}
        type="email"
        value={billingEmail}
        //helperText="Email"
        onChange={(event) => setBillingEmail(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="First Name"
        required={true}
        type="text"
        value={firstName}
        //helperText="First Name"
        onChange={(event) => setFirstName(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Last Name"
        required={true}
        type="text"
        value={lastName}
        //helperText="Last Name"
        onChange={(event) => setLastName(event.target.value)}
      />

      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Address Line 1"
        required={true}
        type="text"
        value={billingAddress}
        //helperText="Address Line 1"
        onChange={(event) => setBillingAddress(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Address Line 2"
        required={false}
        type="text"
        value={billingAddress2}
        //helperText="Address Line 2"
        onChange={(event) => setBillingAddress2(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="City"
        required={true}
        type="text"
        value={billingCity}
        //helperText="City"
        onChange={(event) => setBillingCity(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="State"
        required={true}
        type="text"
        value={billingState}
        //helperText="State"
        onChange={(event) => setBillingState(event.target.value)}
      />

      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Billing Zip"
        required={true}
        type="number"
        value={billingPostalCode}
        //helperText="Zip Code"
        onChange={(event) => setBillingPostalCode(event.target.value)}
      />
      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={true}
        label="Country"
        required={true}
        type="text"
        value={"United States"}
        //helperText="Country"
        onChange={(event) => setBillingCountryRegion("United States")}
      />

      <TextField
        sx={{ width: 300, mt: 1, mb: 1 }}
        disabled={!acceptTerms}
        label="Phone"
        required={false}
        type="phone"
        value={billingPhoneNumber}
        //helperText="Phone"
        onChange={(event) => setBillingPhoneNumber(event.target.value)}
      />

      <div className="mt-3 button-centered">
        <LoadingButton
          disabled={!acceptTerms}
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          className="button mt-1 red-button red-pill-button"
        >
          Submit Payment for {getPriceString(selectedPack)}
        </LoadingButton>
      </div>
    </FormControl>
  );
  const faqAccordion = (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Subscriptions & Credits
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Monthly Credits, Wallet Credits & Credit Packs
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Subscriptions operate on a monthly cadence. All subscriptions
            (including free) provision you a set number of credits each month.
            These credits will be renewed every month to the amounts specified
            by the plan you are on.
          </Typography>
          <br />
          <Typography>
            In addition you also have a 'Wallet' with credits that do not
            expire. Wallet credits can be purchased seperately by buying a
            'credit pack' and are not tied to the monthly subscription schedule.
          </Typography>
          <br />
          <Typography>
            Wallet credits will only be used once your monthly credits run out
            or cannot cover a transaction
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Generative AI
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            AI Generated Images, Curated Images
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            SmartAI Stock Images currently provides 3 types of images:
            <ul>
              <li>
                <strong>Pregenerated Curated Images</strong> - original images
                generated exclusively by the SmartAI Generative Platform (SSGP),
                prompted by the SSGP Prompt Engine and curated by SmartAI
                algorithms and personnel (available now in ALPHA release)
              </li>
              <li>
                <strong>User Generated AI Images</strong> - original images
                generated on-demand by you, the user (available in BETA release)
              </li>
              <li>
                <strong>Artist Images</strong> - original images uploaded to our
                platform by registered SmartAI partner artists (available in
                BETA release)
              </li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Copyright & Usage
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Copyright, Usage, Are These Images Real?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            SmartAI Stock Images are generated via AI models and algorithms.
            These models have demonstrated incredible accuracy in generating
            images that are indistinguishable from real images. However, we do
            not claim that these images are real or accurate in any real world,
            scientific or medical context.
          </Typography>
          <br />
          <Typography>
            SmartAI recommends you use these images in your digital projects to
            provide visual context and to help you communicate your ideas.
            SmartAI does not recommend you use these images as content where
            scientific, medical and real world truths are implied or explicitly
            stated.
          </Typography>
          <br />
          <Typography>
            In very rare cases, images produced by Generative AI models may
            result in an image that closely resembles a copyrighted image. In
            these cases, SmartAI will remove the image from the platform when
            identified or a successful claim is made via{" "}
            <a href="mailto:copyright@smartai.studio">
              copyright@smartai.studio
            </a>{" "}
            and will not charge you for the image. SmartAI will also notify you
            of the removal and will provide you with a credit to your account to
            compensate you for the removal of the image.
          </Typography>

          <br />
          <Typography>
            Please view our{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.termly.io/document/terms-of-use-for-website/376fff7a-0bd7-4e6b-a466-732926a0cbce"
            >
              Terms of Service
            </a>{" "}
            for more information on usage and copyright.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {modalOpen && (
        <Modal
          setOpenModal={setModalOpen}
          styles={{ overlay: { background: "#00FF00" } }}
          title="Confirm Purchase"
          backdropOpacity={0.5}
          portalClassName="modal-container"
          headerClassName="modal-header"
          bodyClassName="modal-body"
        >
          <div className="align-items-center modal-container">
            <div className="text-center align-items-end">
              <div>{getCreditDisplay(selectedPack)}</div>

              <div className="mt-1">
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {inputTermsPrivacy}
                </Box>
              </div>
              <div id="pm_section">
                <div id="or">PayPal</div>
                <div className="">{PayPalOrderSection}</div>

                <div id="or">or</div>
                <div className="cc-form mb-3 align-items-center justify-content-center">
                  <div className="d-flex bg-white flex-column align-items-center justify-content-center">
                    <img src={AuthorizeNetLogo} className="mt-3" />
                    <br />
                    {authorizeNetSection}
                  </div>
                  <div className="">{creditCardForm}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div className="container py-3">
        <header>
          <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
            <h1 className="display-5 fw-normal">Explore Our Plans</h1>
          </div>
        </header>

        <main>
          <div className="row row-cols-1 row-cols-md-4 mb-3 text-center align-items-end">
            {ProPlan}
            {PremiumPlan}
            {StandardPlan}
            {FreePlan}
          </div>

          <h3 className="text-center mb-4">Purchase Credit Packs</h3>

          <div className="row row text-center row-cols-2 row-cols-md-4 gy-2">
            {getCreditDisplay(0)}
            {getCreditDisplay(1)}
            {getCreditDisplay(2)}
            {getCreditDisplay(3)}
          </div>
        </main>

        <div className="bg-banner-info px-4 text-center ">
          <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
            <p className="mb-0 me-3">Frequently Asked Questions (FAQ)</p>
          </div>
        </div>
        {faqAccordion}
      </div>
    </>
  );
}
//export default Pricing;
/*
<div className="accordion accordion-flush" id="accordionFlushExample">
          
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
              </button>
            </h2>
            
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Placeholder content for this accordion, which is intended to
                demonstrate the <code>.accordion-flush</code> class. This is the
                first item's accordion body.
              </div>
            </div>
          </div>
          
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Placeholder content for this accordion, which is intended to
                demonstrate the <code>.accordion-flush</code> class. This is the
                second item's accordion body. Let's imagine this being filled
                with some actual content.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                Placeholder content for this accordion, which is intended to
                demonstrate the <code>.accordion-flush</code> class. This is the
                third item's accordion body. Nothing more exciting happening
                here in terms of content, but just filling up the space to make
                it look, at least at first glance, a bit more representative of
                how this would look in a real-world application.
              </div>
            </div>
          </div>
          */
