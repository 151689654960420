import { apiURI } from "../shared/api.settings";

class ListService {
    static GetCategories = async () => {
        return fetch(`${apiURI}/category`)
            .then((response) => response.json());
    }

    static GetTopics = async () => {
        return fetch(`${apiURI}/topic`)
            .then((response) => response.json());
    }

    static GetColors = async () => {
        return fetch(`${apiURI}/color`)
            .then((response) => response.json());
    }

    static GetMaterials = async () => {
        return fetch(`${apiURI}/materials`)
            .then((response) => response.json());
    }

    static GetTag = async () => {
        return fetch(`${apiURI}/tag`)
            .then((response) => response.json());
    }

    static GetLocation = async () => {
        return fetch(`${apiURI}/location`)
            .then((response) => response.json());
    }

    static GetResolution = async () => {
        return fetch(`${apiURI}/resolution`)
            .then((response) => response.json());
    }
}

export default ListService;