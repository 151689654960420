import { apiURI } from "../shared/api.settings";

class ProductService {

  static GetProductList = async (id) => {
      return fetch(`${apiURI}/product/list`)
          .then((response) => response.json());
  }
  static GetProductPurchases = async (id) => {
    return fetch(`${apiURI}/product/list/${id}`)
        .then((response) => response.json());
  } 
  static GetCreditPacks = async (id) => {
    return fetch(`${apiURI}/credits/packs`)
        .then((response) => response.json());
  } 
  /*
  static GetProductDetails = async (id) => {
    return fetch(`${apiURI}/product/details?productId=${id}`)
        .then((response) => response.json());
  }
    */

 
  static SubscribeEarlyRelease = async (name, email, acceptTerms) => {
    // POST request using fetch with set headers
    const requestOptions = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
        body: JSON.stringify({ 
            user: { 
                id:'0', 
                name:name, 
                userId: 'NA', 
                emailAddress: email, 
                userType:''
            }, 
            mode: 1, 
            Releases: ['stock-image-alpha'] }) 
    };
    fetch(`${apiURI}/subscription/prospect/add`, requestOptions)
        .then(response => response.json());
}
static CapturePayment = async (userId,email,product,creditcard,billing, price) => {
  // console.log("ProductService.CapturePayment: ", userId);
  // console.log("ProductService.CapturePayment: ", JSON.stringify(product));
  // console.log("ProductService.CapturePayment: ", JSON.stringify(creditcard));
  // console.log("ProductService.CapturePayment: ", JSON.stringify(billing));
  // console.log("ProductService.CapturePayment: ", price);
  // console.log("ProductService.PurchaseImage: ", height);

  var body = 
    {
      "user": {
        "id": userId,
      },
      "paymentMode": 4,
      "paymentProfile": {
        "creditCard": {
          "creditCardNumber": creditcard.creditCardNumber,
          "expirationDateString": creditcard.expirationDateString,
          "cvv": creditcard.cvv,      
          "countryRegion": billing.CountryRegion,
          "state": billing.State,
          "city": billing.City,
          "addressLine1": billing.AddressLine1,
          "addressLine2": billing.AddressLine2,
          "postalCode": billing.PostalCode,
          "phoneNumber": billing.PhoneNumber,
          "email": email,
          "firstName": billing.FirstName,
          "lastName": billing.LastName
        }
      }
    };

  // console.log("ProductService - CapturePayment - body: ", body);
  return fetch(`${apiURI}/payment/chargecc`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
}





  static VaryImageUsingCredits = async (userId,productId,imageId) => {
    // console.log("ProductService.VaryImageUsingCredits: ", userId);
    // console.log("ProductService.VaryImageUsingCredits: ", productId);
    // console.log("ProductService.VaryImageUsingCredits: ", imageId);

    var body = 
      {
        "userId": userId,
        "productType": 6,
        "mode": 1,
        "assetId": imageId,
        "productId": `${productId}`,
        "VaryImageUrl": "https://storage.googleapis.com/smartai-stockimage-dev-storage-protected-na/Fabrics/20230220/ac7f6e5f-dae2-43b5-83a6-1e08d9aa307f.png"
      };

    // console.log("ProductService - VaryImageUsingCredits - body: ", body);
    return fetch(`${apiURI}/product/image/vary/credits`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static PurchaseImageUsingCredits = async (userId,productId,imageId) => {
    // console.log("ProductService.PurchaseImageUsingCredits: ", userId);
    // console.log("ProductService.PurchaseImageUsingCredits: ", productId);
    // console.log("ProductService.PurchaseImageUsingCredits: ", imageId);

    var body = 
      {
        "userId": userId,
        "productType": 0,
        "mode": 1,
        "assetId": imageId,
        "productId": `${productId}`
      };

    // console.log("ProductService - PurchaseImageUsingCredits - body: ", body);
    return fetch(`${apiURI}/product/image/purchase/credits`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static PurchaseContentPackPP = async (userId, productId, packId, price, ppTransactionId) => {
    // console.log("ProductService.PurchaseContentPackPP: User:", userId);
    // console.log("ProductService.PurchaseContentPackPP: Product:", productId);
    // console.log("ProductService.PurchaseContentPackPP: Pack:", packId);

    var body = 
      {
        "transactionId": ppTransactionId,
        "TransactionTimestamp": new Date().toUTCString(),
        "userId": userId,      
        "productType": 1, // content pack type
        "mode": 5, // no purchase / just 'purchase' in sassy
        "productId": `${productId}`,
        "AssetId": `${packId}`,
        "price": price,
        "priceCurrency": "USD"
      };
      
    // console.log("ProductService - PurchaseContentPackPP - body: ", JSON.stringify(body));
    return fetch(`${apiURI}/product/contentpack/purchase/pp`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static PurchaseContentPackCC = async (userId, email, productId, packId, price, creditcard) => {
    // console.log("ProductService.PurchaseContentPackCC: User:", userId);
    // console.log("ProductService.PurchaseContentPackCC: Product:", productId);
    // console.log("ProductService.PurchaseContentPackCC: Pack:", packId);
    // console.log("ProductService.PurchaseContentPackCC: Credit:", creditcard);

    var body = 
      {
        "userId": userId,      
        "productType": 1, // content pack type
        "mode": 2, // purchase with $$$
        "productId": `${productId}`,
        "AssetId": `${packId}`,
        "price": price,
        "priceCurrency": "USD",        
        "creditCard": {
            "creditCardNumber": creditcard.creditCardNumber,
            "expirationDateString": creditcard.expirationDateString,
            "cvv": creditcard.cvv,      
            "countryRegion": creditcard.countryRegion,
            "state": creditcard.state,
            "city": creditcard.city,
            "addressLine1": creditcard.addressLine1,
            "addressLine2": creditcard.addressLine2,
            "postalCode": creditcard.postalCode,
            "phoneNumber": creditcard.phoneNumber,
            "email": email,
            "firstName": creditcard.firstName,
            "lastName": creditcard.lastName,
            "paymentProfileId": "",
            "paymentProfileName": ""
          }
      };
      
    // console.log("ProductService - PurchaseContentPackCC - body: ", JSON.stringify(body));
    return fetch(`${apiURI}/product/contentpack/purchase/cc`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static PurchaseCreditPackPP = async (userId, creditPackId, price, currency, ppTransactionId) => {
    // console.log("ProductService.PurchaseCreditPackPP: User:", userId);
    // console.log("ProductService.PurchaseCreditPackPP: Pack:", creditPackId);
    // console.log("ProductService.PurchaseCreditPackPP: PP transaction:", ppTransactionId);
    // console.log("ProductService.PurchaseCreditPackPP: PP price:", price);
    // console.log("ProductService.PurchaseCreditPackPP: PP currency:", currency);

    var body = 
      {
        //"transactionId": ppTransactionId,
        "userId": userId,      
        "mode": 3, // no purchase / just 'purchase' in sassy
        "creditPackId": `${creditPackId}`,        
        "price": price,
        "priceCurrency": currency
      };
      
    // console.log("ProductService - PurchaseCreditPackPP - body: ", JSON.stringify(body));
    return fetch(`${apiURI}/credits/pack/add`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }

  static PurchaseCreditPackCC = async (userId, email, creditPackId, price, currency, creditcard) => {
    // console.log("ProductService.PurchaseCreditPackCC: User:", userId, " email:", email);
    // console.log("ProductService.PurchaseCreditPackCC: Pack:", creditPackId);
    // console.log("ProductService.PurchaseCreditPackCC: Price:", price);
    // console.log("ProductService.PurchaseCreditPackCC: card:", JSON.stringify(creditcard));

    var body = 
    {
      //"transactionId": ppTransactionId,
      "userId": userId,      
      "mode": 4, // no purchase / just 'purchase' in sassy
      "creditPackId": `${creditPackId}`,                
        "price": price,
        "priceCurrency": currency,        
        "creditCard": {
            "creditCardNumber": creditcard.creditCardNumber,
            "expirationDateString": creditcard.expirationDateString,
            "cvv": creditcard.cvv,      
            "countryRegion": creditcard.countryRegion,
            "state": creditcard.state,
            "city": creditcard.city,
            "addressLine1": creditcard.addressLine1,
            "addressLine2": creditcard.addressLine2,
            "postalCode": creditcard.postalCode,
            "phoneNumber": creditcard.phoneNumber,
            "email": email,
            "firstName": creditcard.firstName,
            "lastName": creditcard.lastName,
            "paymentProfileId": "",
            "paymentProfileName": ""
          }
        };
      
      // console.log("ProductService - PurchaseCreditPackCC - body: ", JSON.stringify(body));
    return fetch(`${apiURI}/credits/pack/purchase/cc`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((response) => response.json());
  }
}


export default ProductService;
