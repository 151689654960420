import React from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import {
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  Container,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from "@mui/material";

import './Tiles.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Tiles({ data }) {
  const onMobile = useMediaQuery("(max-width:600px)");
  const onXSMMobile = useMediaQuery("(max-width:331px)");
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" >
      <ImageList
        variant="masonry"
        cols={onMobile ? (onXSMMobile ? 1 : 2) : 5}
        gap={20}
        sx={{ padding: 5 }}
      >
        {data.map((item, index) => (
          <ImageListItem
            key={index}
            onClick={() => navigate({
              pathname: "/image",
              search: createSearchParams({
                id: item.image_id,
              }).toString(),
            })}
            >
            <img
              src={item.public_url_thumbnail}
              srcSet={`${item.public_url_thumbnail} 1x, ${item.public_url_thumbnail} 2x`}
              alt={item.title}
              loading="lazy"
              style={{
                borderRadius: 4,
                boxShadow: "2px 2px 6px 3px rgba(0, 0, 0, 0.3)",
              }}
            />
            <ImageListItemBar
              className="show-on-hover"
              sx={{
                background:
                  "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                  "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                height: "100%",
              }}
              position="top"
              title={item.title.length > 20 ? item.title.substring(0, 20) + "..." : item.title}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.title}`}
                >
                  <FontAwesomeIcon size="lg" icon={solid("circle-info")} />
                </IconButton>
              }
            />
            </ImageListItem>
        ))}
      </ImageList>
    </Container>
  );
}
