import React, { useState, useEffect } from "react";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import MultiDropdown from "../../components/multi-dropdown/MultiDropdown";
import Tiles from "../../components/Tiles/Tiles";
import ImageService from "../../services/image.service";
import ListService from "../../services/list.service";
import { useAuth } from "../../services/auth/use-auth";
import "./Search.css";

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Search2() {
  const auth = useAuth();
  const [init, setInit] = useState(true);
  //const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [colors, setColors] = useState([]);
  const [tags, setTags] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [lastSearchTerm, setLastSearchTerm] = useState("");

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");
  //const [previousTerm, setPreviousTerm] = useState("");
  //const [previousPage, setPreviousPage] = useState(0);
  const [previousNumResults, setPreviousNumResults] = useState(null);
  const MAX_RESULTS = 25;

  /*
  const setPageData = async (searchTerm) => {
    var q = "";
    if (!searchTerm) {
      // console.log("GetImagesBySearchTerm - pageData (no search term)");
      const response = await ImageService.GetCleanImages(0, MAX_RESULTS);
      setData([...response]);
    } else {
      // console.log("GetImagesBySearchTerm - pageData (", searchTerm, ")");
      q = searchTerm;
      const response = await ImageService.GetImagesBySearchTerm(q, 0, MAX_RESULTS);
      setData([...response]);
    }
  };
*/
  const loadMore = async (startIndex, stopIndex, currentItems) => {
    //setPage((prevPage) => prevPage + 1);
    // console.log('lastSearchTerm:', lastSearchTerm, ' searchTerm:', searchTerm, ' page:', page)
    if (lastSearchTerm !== searchTerm) {
      runSearch(searchTerm, 0);
      setPage(0);
    } else {
      runSearch(searchTerm, page + 1);
      setPage((currentPage) => currentPage + 1);
    }
  };

  const runSearch = (q, pageNum) => {
    setShowMore(true);
    setSearchTerm(q);
    if (lastSearchTerm !== q) {
      pageNum = 0
      setPage(0)
    }
    
    setSearchParams(createSearchParams({ q }));
    // if (q === undefined || q === null || q === "") {
    //   q = "random";
    // }
    if (pageNum === 0) {
      setData([]);
    }
    // console.log("RUN SEARCH - searchTerm:", q, " pageNum:", pageNum);

    if (pageNum === undefined) {
      pageNum = 0;
    }
    if (pageNum === null) {
      pageNum = 0;
    }
    if (pageNum < 0) {
      pageNum = 0;
    }
    if (isNaN(pageNum)) {
      pageNum = 0;
    }

    setInit(false);
    if (pageNum === 0) {
      setLoading(true);
    } else {
      setLoadingMore(true);
    }
    ImageService.GetImagesBySearchTerm(q, pageNum, MAX_RESULTS).then(
      (response) => {
        // console.log('page: ', page, ' response: ', response)
        if (response.length === 0) {
          setShowMore(false);
        }
        if (pageNum === 0) {
          setData([...response]);
        } else {
          setData((currentData) => [...currentData, ...response]);
        }
        if (response.length < MAX_RESULTS) {
          setShowMore(false);
        }
        setPreviousNumResults(response.length);
        setSearchTerm(q);
        if (pageNum === 0) {
          setLoading(false);
        } else {
          setLoadingMore(false);
        }
        setLastSearchTerm(q);

        /*
        // console.log(
          "RUN SEARCH - current page index:",
          page ? page : 0,
          " current page size: ",
          MAX_RESULTS,
          " page index requested: ",
          pageNum ? pageNum : 0,
          "term: ",
          q ? q : "none",
          " yielded ",
          response ? response.length : 0,
          " new images ",
          data ? data.length : 0,
          " total images"
        );
        */
      }
    );
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const currentSearchTerm = searchParams.get("q") || "";

      if (searchParams.get("q")) {
        setSearchTerm(searchParams.get("q"));
      }

      const pageData = () => {
        // console.log('calling runSearch from useEffect')
        runSearch(currentSearchTerm, 0);

        // console.log(
        //   "GetImagesBySearchTerm - pageData ",
        //   currentSearchTerm,
        //   " yielded ",
        //   data.length,
        //   " images"
        // );
      };

      const categoriesList = async () => {
        const response = await ListService.GetCategories();
        // console.log("fetching categories");
        setCategories(response);
      };
      const topicsList = async () => {
        const response = await ListService.GetTopics();
        // console.log("fetching topics");
        setTopics(response);
      };

      /*
      const tagsList = async () => {
        const response = await ListService.GetTag();
        // console.log("fetching tags");
        setTags(response);
      };

       const materialsList = async () => {
        const response = await ListService.GetMaterials();
        // console.log("fetching materials");
        setMaterials(response);
      };
      */
     
      const colorList = async () => {
        const response = await ListService.GetColors();
        // console.log("fetching colors");
        setColors(response);
      };
     

      pageData(searchParams.get("q"));

      //tagsList();
      colorList();
      categoriesList();
      topicsList();
 
    }
    return () => {
      mounted = false;
    };
  }, []);

  /*
  const handleSearchChange = (value) => {
    // console.log("handleSearchChange - value: ", value);
    setSearchTerm(value);
    setSearchParams(createSearchParams({ q: value }));
    setPageData(value);
  };

  const topicsCombo = () => {
    <div className="col">
      <div className="px-4 text-center">
        <div className="d-flex align-items-center justify-content-center">
          <MultiDropdown className="w-100" list={topics}>
            Topic
          </MultiDropdown>
        </div>
      </div>
    </div>;
  };

  const tagsCombo = () => {
    <div className="col">
      <div className="px-4 text-center">
        <div className="d-flex align-items-center justify-content-center">
          <MultiDropdown className="w-100" list={tags}>
            Tag
          </MultiDropdown>
        </div>
      </div>
    </div>;
  };

  const materialsCombo = () => {
    <div className="col">
      <div className="px-4 text-center">
        <div className="d-flex align-items-center justify-content-center">
          <MultiDropdown className="w-100" list={materials}>
            Materials
          </MultiDropdown>
        </div>
      </div>
    </div>;
  };
*/

  const colorsCombo = () => {
    <div className="col">
      <div className="px-4 text-center">
        <div className="d-flex align-items-center justify-content-center">
          <MultiDropdown className="w-100" list={colors}>
            Color/Mood
          </MultiDropdown>
        </div>
      </div>
    </div>;
  };


  const categoriesCombo = () => {
    <div className="col">
      <div className="px-4 text-center">
        <div className="d-flex align-items-center justify-content-center">
          <MultiDropdown className="w-100" list={categories}>
            Category
          </MultiDropdown>
        </div>
      </div>
    </div>;
  };

  const getSearchList = () => {
    /*
    const combined = [
      ...categories,
      ...tags,
      ...topics,
      ...colors,
      ...materials,
    ];
    */
    const combined = [
      ...categories,
      ...topics,
    ];
    combined.map((e) => e.trim().charAt(0).toUpperCase() + e.trim().slice(1));
    return [...new Set(combined)].sort();
  };

  const getRandomTag = () => {
    // set query praramter to random tag
    const list = getSearchList();
    const index = Math.floor(Math.random() * list.length);
    return list[index];
  };

  const getResultsString = () => {
    let buffer = "";
    if (!data) {
      buffer = "No results found";
    } else if (loading) {
      buffer = "Loading...";
    } else if (data.length === 0) {
      buffer = "No results found";
    } else if (data.length === 1) {
      buffer = "1 result found";
    } else if (data.length % MAX_RESULTS === 0) {
      buffer = "first " + data.length + " results found";
    } else {
      buffer = data.length + " results";
    }

    return buffer + " for '" + searchTerm + "'";
  };

  return (
    <Box sx={{ minHeight: "73vh" }}>
      {/* <div className="bg-hero px-4 py-5 text-center">
        <input
          className="col-lg-6 mx-auto"
          placeholder="Search for images"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
         <button
          id="searchQuerySubmit"
          type="submit"
          name="searchQuerySubmit"
          onClick={(e) => handleSearchChange(searchTerm)}
        >
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>
      </div> */}
      {/* Center Box */}
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <Box sx={{ minWidth: "90%", display: "flex", justifyContent: "center" }}>
        <FormControl
          variant="outlined"
          sx={{ width: "80%", maxWidth: 1000, margin: "auto" }}
        >
          <Autocomplete
            sx={{ minWidth: "80%", maxWidth: 500, margin: "auto", my: 2 }}
            freeSolo
            disableClearable
            groupBy={(option) => option[0]}
            options={getSearchList()}
            value={searchTerm}
            onChange={(e, value) => runSearch(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Image Search"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          {/* <FormHelperText>Start searching for an image</FormHelperText> */}
        </FormControl>
      </Box>
      <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-1">
        <Chip
          hidden={init}
          color="secondary"
          sx={{ margin: 0.2 }}
          label={
            <Typography sx={{ margin: 1, fontFamily: "Raleway", fontSize: 12 }}>
              {getResultsString()}
            </Typography>
          }
        />
        <Button
          sx={{ ml: 4 }}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => runSearch(getRandomTag())}
          endIcon={<FontAwesomeIcon icon={solid("dice")} />}
        >
          Explore
        </Button>
      </div>
      <div className="container mb-5">
        <div className="row row-cols-2 row-cols-md-3 row-cols-xl-5 gy-3">
          {categoriesCombo}
          {colorsCombo}
        </div>
      </div>

      {!auth.user ? (
        <div className="bg-banner px-4 text-center ">
          <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
            <p className="mb-0 me-3 text-white">
              Want to remove the watermark?
            </p>
            <div className="button" onClick={() => navigate("/signup")}>
              Join For Free
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        {loading && !loadingMore ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <>
            <Tiles data={data} loadMore={loadMore}></Tiles>
            { loadingMore ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10vh",
                }}
              >
                <CircularProgress color="inherit" />
              </Box> ) : (<></>) }
            </>
        )}
        {showMore && !loading ? (
          <div className="px-4 my-5 text-center">
            <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
              <h5 className="fw-bold color-primary">
                <Button
                  className="rounded-pill"
                  variant="contained"
                  onClick={() => {
                    loadMore();
                  }}
                >
                  Load More Images
                </Button>
              </h5>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </Box>
  );
}
