import React, { useState, useEffect } from "react";
import MultiDropdown from "../../components/multi-dropdown/MultiDropdown";
import ImageService from "../../services/image.service";
import UserService from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth/use-auth";
import { Image } from "react-bootstrap";

import "./profile.css";
import GalleryTiles from "../../components/galleryTiles/GalleryTiles";
import { SocialIcon } from "react-social-icons";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";

function Profile() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [subscription, setSubscription] = useState([]);
  const [credits, setCredits] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGalleryImages = () => {
    setLoading(true);
    if (auth.user) {
      // console.log(
      //   "fetching gallery images for user: ",
      //   auth.user.uid,
      //   " page: ",
      //   page,
      //   " ..."
      // );
      ImageService.GetUserImages(
        auth.user.uid,
        0,
        48,
        auth.user.auth.currentUser.accessToken
      )
        .then((res) => {
          // console.log("response", res);
          setData([...res]);
          setLoading(false);
        })
        .catch((err) => {
          // console.log("error", err);
          setLoading(false);
        });
    }
  };

  const loadMore = async (startIndex, stopIndex, currentItems) => {
    /*
    setPage((prevPage) => prevPage + 1);
    const response = await ImageService.GetUserImages(auth.user.uid, page);
    setData((prevData) => [...prevData, ...response]);
    // console.log("fetching more Data");
    // console.log("data", data);
    */
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      ImageService.GetUserImages(auth.user.uid, 0)
        .then((res) => {
          setData([...res]);
          setLoading(false);
        })
        .catch((err) => {
          // console.log("error", err);
          setLoading(false);
        });
      UserService.GetUserProfile(
        auth.user.uid,
        auth.user.auth.currentUser.accessToken
      ).then((res) => {
        setUser(res.data);
      });
      UserService.GetUserSubscription(
        auth.user.uid,
        auth.user.auth.currentUser.accessToken
      )
        .then((response) => {
          // console.log(
          //   "Logged in, credit  info for auth.user.id:",
          //   auth.user.uid,
          //   "\n",
          //   response
          // );
          setCredits(response.subscription.creditProfile);
          setSubscription(response.subscription);
          // // console.log("Subscription: ", JSON.stringify(response.subscription));
        })
        .catch((err) => {
          // console.log("error", err);
        });
      }
      return () => mounted = false;
      // // console.log("No Subscription - Not Logged in ", JSON.stringify(credit));
    /*
    const getSubscription = async () => {
      if (auth.user) {
        const response = await UserService.GetUserCredits(auth.user.uid);
        // console.log(
          "Logged in, subscription info for auth.user.id:",
          auth.user.uid,
          "\n",
          response
        );
        setSubscription(response.creditProfile);
        // console.log("CreditProfile: ", JSON.stringify(response.creditProfile));
      } else {
        //const response = await UserService.GetUserCredits("User123");
        var credit = {
          creditProfile: {
            numSubscriptionCreditsRemaining: -1,
            numWalletCreditsRemaining: -1,
            numPendingCredits: -1,
          },
        };
        setSubscription(credit);
        // console.log("No Subscription - Not Logged in ", JSON.stringify(credit));
      }
    };
    */
  }, [auth]);

  const dropdownFilters = (
    <div className="d-flex flex-wrap align-content-start mb-3">
      <MultiDropdown
        className="mt-3 me-3"
        list={["Category 1", "Category 2", "Category 3"]}
      >
        Category
      </MultiDropdown>
      <MultiDropdown
        className="mt-3 me-3"
        list={["Topic 1", "Topic 2", "Topic 3"]}
      >
        Topic
      </MultiDropdown>
      <MultiDropdown className="mt-3 me-3" list={["Tag 1", "Tag 2", "Tag 3"]}>
        Tag
      </MultiDropdown>
      <MultiDropdown
        className="mt-3 me-3"
        list={["Color 1", "Color 2", "Color 3"]}
      >
        Color
      </MultiDropdown>
    </div>
  );

  return (
    <div>
      <div className="container my-3">
        <div className="row mb-2">
          <div className="col d-flex justify-content-center">
            <Avatar
              sx={{
                width: 140,
                height: 140,
                margin: "auto",
              }}
              src={auth.user.photoURL}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex justify-content-center">
            <div className="display-5 fw-bold">{auth.user.displayName}</div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col d-flex justify-content-center">
            {user && user.facebookUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.facebookUrl}
              />
            ) : (
              <></>
            )}
            {user && user.instagramUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.instagramUrl}
              />
            ) : (
              <></>
            )}
            {user && user.twitterUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.twitterUrl}
              />
            ) : (
              <></>
            )}
            {user && user.youTubeUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.youTubeUrl}
              />
            ) : (
              <></>
            )}
            {user && user.tikTokUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.tikTokUrl}
              />
            ) : (
              <></>
            )}
            {user && user.linkedInUrl ? (
              <SocialIcon
                className="m-2"
                style={{ height: 40, width: 40 }}
                target="_blank"
                url={user.linkedInUrl}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <div className="fw-bold">
              Membership type: {subscription.subscriptionName}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex justify-content-center">
            <div className="fw-bold">
              Subscription Credit Balance:{" "}
              {credits.numSubscriptionCreditsRemaining}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <div className="fw-bold">
              Wallet Credit Balance: {credits.numWalletCreditsRemaining}
            </div>
          </div>
        </div>

        <div className="row mb-2 mt-2">
          <div className="col d-flex justify-content-center">
            <button
              type="button"
              className="button"
              onClick={() =>
                navigate({
                  pathname: "/profile/edit",
                  search: "?tab=settings",
                })
              }
            >
              Edit Profile
            </button>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col d-flex justify-content-center">
            <button
              type="button"
              className="button"
              onClick={() =>
                navigate({
                  pathname: "/profile/edit",
                  search: "?tab=membership",
                })
              }
            >
              Manage Membership
            </button>
          </div>
        </div>
        <div className="row my-4">
          <div className="col d-flex justify-content-center">
            <button
              type="button"
              className="tab-button active me-1"
              onClick={() => getGalleryImages()}
            >
              Purchases
            </button>
            <button
              type="button"
              disabled={true}
              className="tab-button me-1 disabled opacity-50"
              onClick={() => navigate("/profile/uploads")}
            >
              Uploads (BETA FEATURE)
            </button>
          </div>
        </div>
        <Typography variant="h4" textAlign="center" className="mb-3">
          My Image Gallery
        </Typography>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "34vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <GalleryTiles data={data} loadMore={loadMore}></GalleryTiles>
        )}
      </div>
    </div>
  );
}

export default Profile;
