import React, { useState, useEffect } from "react";
//import MultiDropdown from "../../components/multi-dropdown/MultiDropdown";
import Tiles from "../../components/Tiles/Tiles";
import ImageService from "../../services/image.service";
import ListService from "../../services/list.service";
import {
  Link,
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import Searchbar from "../../components/searchbar/searchbar";
import { useAuth } from "../../services/auth/use-auth";
import UserService from "../../services/user.service";
import useGoogleOneTap from "../../hooks/useGoogleOneTap";
import { GoogleAuthProvider } from "firebase/auth";
import "./Home.css";
import {
  Button,
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { Check, CheckCircleOutline } from "@mui/icons-material";
//import { HorizontalRule } from "@mui/icons-material";

export default function Home() {
  const auth = useAuth();
  //const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  //const [filter, setFilter] = useState("recent");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") || "");
  const [authLoading, setAuthLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [showMore, setShowMore] = useState(true);

  const handleGoogleOneTapResponse = async (response) => {
    setAuthLoading(true);
    try {
      // // console.log(
      //   "SUCCESS: Signin with Google One Tap Success: ",
      //   response.credential
      // );
      // console.log("loading: ", loading);
      const credential = response.credential;
      // console.log("google one tap response: ", response);
      const googleAuthCredential = GoogleAuthProvider.credential(credential);
      // console.log("googleAuthCredential: ", googleAuthCredential);
      var result = await auth.signInWithCredentialFlow(googleAuthCredential);
      // console.log("result: ", result);
      var userDetails = auth.getAdditionalUserInfoFlow(result);
      // console.log("userDetails: ", userDetails);
      var currentUser = result.user;
      // console.log("Successfully created account using Google");
      const isNewUser = userDetails.isNewUser;
      if (isNewUser) {
        // console.log("Creating New user");
        const newUser = {
          userId: currentUser.uid,
          email: currentUser.email,
          password: null,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
        };
        var createNewUserRes = await UserService.CreateNewUserAccount(newUser);
        // console.log(createNewUserRes);
        if (createNewUserRes?.userId) {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      // console.log("ERROR: Signin with Google One Tap Error: ", error);
    }
    setAuthLoading(false);
    // console.log("loading: ", loading);
  };

  const handleGoogleButtonClick = (isShowing) => {
    if (isShowing) {
      setAuthLoading(true);
      // console.log("Google OneTap Button Clicked");
      // console.log("event: ", isShowing);
    } else {
      setAuthLoading(false);
    }
  };

  useGoogleOneTap({
    // onError: (error) => // console.log("Google OneTap Error: ", error),
    // onComplete: (response) => // console.log("Google OneTap Success: ", response),
    onDisplay: (isShowing) => handleGoogleButtonClick(isShowing),
    showPrompt: true,
    disabled: auth.user ? true : false,
    disableCancelOnUnmount: false,
    googleConfig: {
      auto_select: auth.user ? true : false,
      cancel_on_tap_outside: false,
      callback: handleGoogleOneTapResponse,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    },
  });

  const loadMore = async (startIndex, stopIndex, currentItems) => {
    setLoading(true);
    setLoadingMore(true);
    // console.log("loading next page: ", page + 1);
    if (selectedCategory === "All") {
      try {
        const response = await ImageService.GetCleanImages(page + 1);
        setPage((prevPage) => prevPage + 1);
        setData((prevData) => [...prevData, ...response]);
      } catch (error) {
        // console.log("error loading more images: ", error);
      }
    } else {
      // filter by category
      await filterData(selectedCategory, page + 1);
      setPage((prevPage) => prevPage + 1);
    }
    setLoading(false);
    setLoadingMore(false);
    // console.log("fetching more Data");
    // console.log("data", data);
  };

  const handleCategoryChange = (e) => {
    if (e.target.value !== selectedCategory) {
      setPage(0);
      setData([]);
    }
    setShowMore(true);
    setSelectedCategory(e.target.value);
    // console.log("handleCategoryChange() - entered");
    // console.log("handleCategoryChange() - e.target.value:", e.target.value);
    filterData(e.target.value);
  };

  const filterData = async (value, pageParam = 0) => {
    // console.log("filterData() - entered: ", value);
    setLoading(true);
    // console.log("filterData() - entered");
    //setPage(0);
    // get value from filter select
    //var value = document.getElementsByName("filter1").value;
    // console.log("filterData() - FILTER(", JSON.stringify(value), ")");

    if (value === "All") {
      // console.log("filterData() - ALL");
      ImageService.GetCleanImages(pageParam)
        .then((response) => {
          // console.log("filterData() - ALL - DATA:", response);
          if (response.length === 0) {
            setShowMore(false);
          } else {
            setData([...response]);
            setShowMore(true);
          }
          if (searchParams.has("q")) {
            searchParams.delete("q");
            setSearchParams(searchParams);
          }
          setLoading(false);
        })
        .catch((error) => {
          // console.log("error: ", error);
          setLoading(false);
        });
    } else {
      setSearchParams(createSearchParams({ q: value }));
      // console.log("filterData() - CATEGORY:", value);
      ImageService.GetImagesByCategory(value, pageParam).then((response) => {
        // console.log("filterData() - CATEGORY:", value, "DATA:", response);
        if (pageParam !== 0) {
          // in a load more scenario
          setData((currentData) => [...currentData, ...response]);
        } else {
          // in a new filter scenario
          setData([...response]);
        }
        if (response.length === 0) {
          setShowMore(false);
        }
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true)
      if (searchParams.has("q")) {
        setSelectedCategory(searchParams.get("q"));
      } else {
        setSelectedCategory("All");
      }
      ListService.GetCategories().then((response) => {
        // console.log("fetched categories: ", response);
        setCategories(["All", ...response.sort()]);
      });

      const query = searchParams.get("q");
      // console.log("query", query);

      if (query) {
        ImageService.GetImagesByCategory(query, page).then((response) => {
          setData([...response]);
          setLoading(false);
        });
        // document.getElementById("filter1").value = query;
      } else {
        ImageService.GetCleanImages(page).then((response) => {
          setData([...response]);
          setLoading(false);
        });
      }
    }
    return () => {
      mounted = false;
    };
  }, [searchParams]);

  const handleFilterChange = (e) => {
    // console.log("handleFilterChange() - entered");
    // console.log("handleFilterChange() - e.target.value:", e.target.value);
    //setFilter(e.target.value);
    filterData(e.target.value);
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={authLoading}
      >
        {/* <CircularProgress color="inherit" /> */}
      </Backdrop>
      <div className="bg-hero px-4 py-5 text-center">
        <h1 className="display-5 fw-bold">
          Stunning AI-Generated stock images
        </h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            Original images and illustrations for all your digital projects
            including website, social media, presentations, and more!
          </p>
        </div>
        <Searchbar />
      </div>
      {!auth.user ? (
        <div className="bg-banner px-4 text-center ">
          <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
            <p className="mb-0 me-3 register-text">
              Register to access more features:
            </p>
            <div className="button" onClick={() => navigate("/signup")}>
              Join For Free
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Box flex>
        <Box
          sx={{
            margin: "auto",
            width: "80%",
            mt: 3,
            mb: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl
            variant="outlined"
            sx={{ minWidth: 280, margin: "auto" }}
          >
            <InputLabel>Categories</InputLabel>
            <Select
              value={categories.length > 0 ? selectedCategory: ''}
              data={categories}
              label="Categories"
              renderValue={(value) => value}
              onChange={(e) => handleCategoryChange(e)}
            >
              {categories.map((category) => (
                <MenuItem
                  key={category}
                  value={category}
                  selected={category === selectedCategory}
                >
                  {selectedCategory === category ? (
                    <ListItemIcon>
                      <Check />
                    </ListItemIcon>
                  ) : null}
                  <ListItemText inset={selectedCategory !== category}>
                    {category}
                  </ListItemText>
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Select a category to browse images</FormHelperText>
          </FormControl>
        </Box>
        {loading && !loadingMore ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "10vh",
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <>
            <Tiles data={data} loadMore={loadMore}></Tiles>
            { loadingMore ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10vh",
                }}
              >
                <CircularProgress color="inherit" />
              </Box> ) : (<></>) }
            </>
        )}
        {showMore ? (
          <div className="px-4 my-5 text-center">
            <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
              <h5 className="fw-bold color-primary">
                <Button
                  className="rounded-pill"
                  variant="contained"
                  onClick={() => {
                    loadMore();
                  }}
                >
                  Load More Images
                </Button>
              </h5>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="bg-banner-info px-4 text-center ">
          <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
            <p className="mb-0 me-3">Browse All Categories</p>
          </div>
        </div>
        <p />
        <Box
          sx={{
            margin: "auto",
            width: "50%",
            mt: 3,
            mb: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="row text-center row-cols-2 row-cols-md-3 gy-5">
            {categories ? (
              categories.map((category, index) => {
                return (
                  <div className="col" key={index}>
                    <Link
                      to={`/search?q=${category}`}
                      state={{ category }}
                      className="text-decoration-none m-auto"
                    >
                      {category}
                    </Link>
                  </div>
                );
              })
            ) : (
              // <Box
              //   sx={{
              //     display: "flex",
              //     justifyContent: "center",
              //     alignItems: "center",
              //     height: "10vh",
              //   }}
              // >
              //   <CircularProgress color="inherit" />
              // </Box>
              <></>
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
}
