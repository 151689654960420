import React from "react";

function Modal({ setOpenModal, title, children }) {
  const _clickHandler = (e) => {
    //do thing
    setOpenModal(false);
  };
  const testStyle = {
    display: 'block'
  }
  return (
    <div className="modal fade show" tabindex="-1" style={testStyle}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-center w-100">{title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={_clickHandler}
            ></button>
          </div>
          <div className="modal-body">
            {children}
          </div>
          {/* <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={_clickHandler}
            >
              Save changes
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Modal;
