import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions,
  Avatar,
  IconButton,
  Box,
  Typography,
  Link,
  Chip,
} from "@mui/material";

import "./PackTile.css";

const PackTile = (props) => {
  const data = props.data;

  const [open, setOpen] = useState(false);
  const [mouseover, setMouseover] = useState(false);

  const navigate = useNavigate();

  const _goToTileDetailsPage = () => {
    navigate({
      pathname: "/pack",
      search: createSearchParams({
        id: data.content_pack_id,
      }).toString(),
    });
  };

  // Event handlers to modify state values
  const _mouseEnter = (e) => {
    e.preventDefault();
    if (mouseover === false) {
      setMouseover(true);
    }
  };
  const _mouseLeave = (e) => {
    e.preventDefault();
    if (mouseover === true) {
      setMouseover(false);
    }
  };
  const _clickHandler = (e) => {
    e.preventDefault();
    _goToTileDetailsPage();
  };

  const getSafeTitle = (title) => {
    if (title) {
      if (title.length <= 50) {
        return title;
      } else {
        return title.substring(0, 50) + "...";
      }
    }
    return "";
  };

  return (
    
      <div className="tile" onClick={_clickHandler}>
        <Card className="img-wrapper" sx={{ height: '320px' }}>
          <CardHeader
            title={
              <div>
                <Chip 
                  color="secondary"  
                  label={
                    <Typography variant="contained">
                      {" " + data.category + " "}
                    </Typography>} />
                &nbsp;&nbsp;
                <Chip color="primary" 
                  label={
                  <Typography>
                    {data ? "$" + data.price : ""}
                  </Typography>} />  
                  <br />
      
                  
              </div>
            }            
          />

          <CardMedia
            component="img"
            className="img-responsive img-thumbnail"
            style={props.style}
            onMouseEnter={_mouseEnter}
            onMouseLeave={_mouseLeave}
            src={data.cover_image_url}           
            alt={data.name}
          />
          <CardContent>


            <Typography variant="body3">
              {getSafeTitle(data.title) + "..."}
            </Typography>
            <Typography variant="body2">{data ? ("created by: " + data.created_by) : ''}</Typography>
          </CardContent>
        </Card>
      </div>
  );
};

export default PackTile;
