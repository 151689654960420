import React, { useState, useEffect } from "react";
import { Link, useNavigate, createSearchParams, useSearchParams, useLocation } from "react-router-dom";
import MultiDropdown from "../../components/multi-dropdown/MultiDropdown";
import Tiles from "../../components/Tiles/Tiles";
import Searchbar from "../../components/searchbar/searchbar";
import ImageService from "../../services/image.service";
import ListService from "../../services/list.service";
import { useAuth } from "../../services/auth/use-auth";
import "./Search.css";

export default function Categories() {
  const auth = useAuth();
  //const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  //const [page, setPage] = useState(1);
  const [categories, setCategories] = useState(null);
  const [topics, setTopics] = useState(null);
  const [colors, setColors] = useState(null);
  const [tags, setTags] = useState(null);
  const [materials, setMaterials] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") || "");

  const refreshPage = () => {
    navigate(0);
}

  const sizes = [
    { columns: 1, gutter: 10 },
    { mq: "768px", columns: 3, gutter: 25 },
    { mq: "1024px", columns: 4, gutter: 50 },
  ];


  useEffect(() => {

    /*
    const search = () => {
      if (searchTerm.length !== 0) {
        navigate({
          pathname: "/search",
          search: createSearchParams({
            q: searchTerm,
          }).toString(),
        });
      }
    };
*/

    const categoriesList = async () => {
      const response = await ListService.GetCategories();
      // console.log("fetching categories");
      setCategories(response);
    };
    const tagsList = async () => {
      const response = await ListService.GetTag();
      // console.log("fetching tags");
      setTags(response);
    };
   
    const topicsList = async () => {
      const response = await ListService.GetTopics();
      // console.log("fetching topics");
      setTopics(response);
    };
    const colorList = async () => {
      const response = await ListService.GetColors();
      // console.log("fetching colors");
      setColors(response);
    };
    const materialsList = async () => {
      const response = await ListService.GetMaterials();
      // console.log("fetching materials");
      setMaterials(response);
    };


    categoriesList();
    tagsList();
    topicsList();
    colorList();

  }, []);


  const topicsCombo = () => {
    <div className="col">
        <div className="px-4 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <MultiDropdown
              className="w-100"
              list={topics}
            >
              Topic
            </MultiDropdown>
          </div>
        </div>
      </div>
};

const tagsCombo = () => {
      <div className="col">
        <div className="px-4 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <MultiDropdown
              className="w-100"
              list={tags}
            >
              Tag
            </MultiDropdown>
          </div>
        </div>
      </div>
};

const colorsCombo = () => {
      <div className="col">
        <div className="px-4 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <MultiDropdown
              className="w-100"
              list={colors}
            >
              Color/Mood
            </MultiDropdown>
          </div>
        </div>
      </div>
};

const materialsCombo = () => {
      <div className="col">
        <div className="px-4 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <MultiDropdown
              className="w-100"
              list={materials}
            >
              Materials
            </MultiDropdown>
          </div>
        </div>
      </div>
};

const categoriesCombo = () => {
<div className="col">
        <div className="px-4 text-center">
          <div className="d-flex align-items-center justify-content-center">
            <MultiDropdown
              className="w-100"
              list={categories}
            >
              Category
            </MultiDropdown>
          </div>
        </div>
      </div>
};


  return (
    <div>
      <div className="bg-hero px-4 py-5 text-center">
        <Searchbar/>
      </div>

      <div className="container mb-5">
        <div className="row row-cols-2 row-cols-md-3 row-cols-xl-5 gy-3">
          
          {categoriesCombo}
          {colorsCombo}

        </div>
      </div>
      
      { 
        !auth.user ? (
          <div className="bg-banner px-4 text-center ">
            <div className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
              <p className="mb-0 me-3 text-white">
                Want to remove the watermark?
              </p>
              <div className="button" onClick={() => navigate("/signup")}>Join For Free</div>
            </div>
          </div>
        ) : <></>
      }
      <div>
       <div className="container mb-5">
          { data.length > 0 ? (
            <Tiles data={data} loadMore={null} >
            </Tiles>
          ) : (
            <div className="row">
              <p/>
              <h3 className="col text-center">No images found using search filter <span className="fw-bold">{searchTerm}</span></h3>
            </div>
          )}
        </div>
        
        <div className="px-4 my-5 text-center">
          <div id='categoryAnchor' className="d-flex align-items-center justify-content-center col-lg-6 mx-auto py-2">
            <h5 className="fw-bold color-primary">Browse All Categories</h5>
          </div>
        </div>
        <div className="container d-flex align-items-center justify-content-center mb-5">
          <div className="row text-center row-cols-2 row-cols-md-3 gy-5">
            {categories ? (
              categories.map((category, index) => {
                return (
                  <div className="col" key={index}>
                    <Link to={`/search?q=${category}`} state={{category}} className="text-decoration-none m-auto">
                      {category}
                    </Link>
                  </div>
                );
              })
            ) : (
              <div className="row">
                <div className="col text-center">Loading...</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );         
};
