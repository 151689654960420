import React, { useState, useEffect } from "react";
import PackTile from "./PackTile";
import { Masonry, useInfiniteLoader } from "masonic";

export default function PackTiles({ data, loadMore }) {
  const checkLoadMore = useInfiniteLoader(loadMore, {
    isItemLoaded: (index, items) => !!items[index],
  });

  // Create tile for each item in data array
  // Pass data to each tile and assign a key
  return (
    <Masonry
      items={data}
      columnGutter={15}
      columnWidth={240}
      onRender={checkLoadMore}
      render={(props) => (
        <PackTile
          style={{ height:160, width: props.width }}
          data={props.data}
          key={props.index}
        />
      )}
    ></Masonry>
  );
}
