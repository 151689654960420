/* eslint-disable react/function-component-definition */
import * as React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth/use-auth";
import useGoogleOneTap from "../../hooks/useGoogleOneTap";
import { GoogleAuthProvider } from "firebase/auth";
import UserService from "../../services/user.service";
import { Backdrop, Box, CircularProgress, Divider } from "@mui/material";

const SignUpPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [displayName, setDisplayName] = React.useState("");
  const [showEmailPassword, setShowEmailPassword] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [emailValid, setEmailValid] = React.useState(false);
  const [passwordValid, setPasswordValid] = React.useState(false);
  const [displayNameValid, setDisplayNameValid] = React.useState(false);
  const [formValid, setFormValid] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const onSetDisplayName = (displayName) => {
    setDisplayName(displayName);
    setDisplayNameValid(displayName.length > 0);
    setFormValid(displayNameValid && emailValid && passwordValid);
  };
  const onSetEmail = (email) => {
    setEmail(email);
    setEmailValid(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/.test(email));
    setFormValid(displayNameValid && emailValid && passwordValid);
  };
  const onSetPassword = (password) => {
    setPassword(password);
    setPasswordValid(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm.test(password)
    );
    setFormValid(displayNameValid && emailValid && passwordValid);
  };
  const handleSignUp = async () => {
    setLoading(true);
    // console.log("loading...");
    try {
      const signUpRes = await auth.signup(email, password, displayName);
      if (signUpRes?.userId) {
        var currentUser = await auth.signin(email, password);
        // console.log("Successfully created account using email and password", currentUser);
        // console.log("loading complete...");
        // navigate("/pricing", { state: { email, password, displayName } }); // BETA+
        navigate("/");
      }
    } catch (error) {
      // console.log(error);
      setError(true);
    }
    setLoading(false);
  };
  const handleGoogleOneTapResponse = async (response) => {
    setLoading(true);
    try {
      // console.log(
      //   "SUCCESS: Signin with Google One Tap Success: ",
      //   response.credential
      // );
      const credential = response.credential;
      const googleAuthCredential = GoogleAuthProvider.credential(credential);
      // console.log("googleAuthCredential: ", googleAuthCredential);
      var result = await auth.signInWithCredentialFlow(googleAuthCredential);
      // console.log("result: ", result);
      var userDetails = auth.getAdditionalUserInfoFlow(result);
      // console.log("userDetails: ", userDetails);
      var currentUser = result.user;
      // console.log("Successfully created account using Google");
      const isNewUser = userDetails.isNewUser;
      if (isNewUser) {
        // console.log("Creating New user");
        const newUser = {
          userId: currentUser.uid,
          email: currentUser.email,
          password: null,
          displayName: currentUser.displayName,
          photoURL: currentUser.photoURL,
        };
        var createNewUserRes = await UserService.CreateNewUserAccount(newUser);
        // console.log(createNewUserRes);
        if (createNewUserRes?.userId) {
          navigate("/pricing");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      // console.log("ERROR: Signin with Google One Tap Error: ", error);
    }
    setLoading(false);
  };

  useGoogleOneTap({
    // onError: (error) => // console.log("Google OneTap Error: ", error),
    // onComplete: (response) => // console.log("Google OneTap Success: ", response),
    disabled: auth.user ? true : false,
    showPrompt: false,
    disableCancelOnUnmount: false,
    googleConfig: {
      auto_select: true,
      cancel_on_tap_outside: false,
      callback: handleGoogleOneTapResponse,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    },
  });

  return (
      <main className="fill d-flex align-items-center">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Paper
          sx={{
            width: 300,
            mx: "auto", // margin left & right
            my: 4, // margin top & botom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRadius: "sm",
            boxShadow: "md",
          }}
        >
          <div>
            <Typography level="h4" component="h1">
              <b>Create SmartAI Account</b>
            </Typography>
          </div>
          <div
            id="google-signin-button"
            className="g_id_signin"
            data-type="standard"
            data-context="signup"
            data-size="large"
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-logo_alignment="left"
          ></div>
          <Box sx={{ display: "flex", alignItems: "center" }}>
              <Link
                sx={{ color: "#d74a4a", pt: 0.5 }}
                onClick={() => setShowEmailPassword(!showEmailPassword)}
              >
                Sign up with an Email and Password
              </Link>
          </Box>
          {showEmailPassword ? (
            <>
              <Divider sx={{ my: 2 }} />
              <TextField
                required
                type="text"
                placeholder="Name"
                onChange={(e) => onSetDisplayName(e.target.value)}
                label="Name"
              />
              <TextField
                required
                type="email"
                error={emailValid || email.length < 4 ? false : true}
                placeholder="Email"
                onChange={(e) => onSetEmail(e.target.value)}
                // pass down to FormLabel as children
                label="Email"
              />
              {!emailValid && email.length > 4 ? (
                <FormHelperText
                  sx={(theme) => ({
                    color: "red",
                    mx: "auto",
                  })}
                >
                  Please enter a valid email address
                </FormHelperText>
              ) : (
                <></>
              )}
              <TextField
                type="password"
                required
                error={!passwordValid && password.length < 4 ? true : false}
                placeholder="Password"
                onChange={(e) => onSetPassword(e.target.value)}
                label="Password"
              />
              {!passwordValid && password.length > 0 ? (
                <FormHelperText
                  sx={(theme) => ({
                    color: "red",
                    mx: "auto",
                  })}
                >
                  <ul>
                    <li>at least 8 characters</li>
                    <li>
                      must contain at least 1 uppercase letter, 1 lowercase
                      letter, and 1 number
                    </li>
                  </ul>
                </FormHelperText>
              ) : (
                <></>
              )}
              <Button
                variant="contained"
                sx={{ mt: 1 }}
                onClick={handleSignUp}
                disabled={!emailValid || !passwordValid || !displayNameValid}
              >
                Get Started
              </Button>
              <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', textAlign: "center" }}>
                Already have an account?
                <Link to="/login">
                      Back to Login
                </Link>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Paper>
      </main>
  );
};

export default SignUpPage;
